import React, { Component } from 'react';
import { Button } from "@mui/material";
import { styled } from '@mui/material/styles';
import { RoyalBlue } from '../colors/Colors';

const CustomButton = styled((props) => <Button {...props} />)(({ theme }) => ({
    marginTop: 20,
    borderRadius: "10px",
    minHeight: '46px',
    backgroundColor: RoyalBlue,
    "&:hover": {
        backgroundColor: RoyalBlue,
    }
}));
export class CustomFilledButton extends Component {
    render() {
        const { children, ...other } = this.props;
        return (
            <CustomButton
                {...{
                    ...other,
                }}
            >
                {children}
            </CustomButton>
        );
    }
}
export default (CustomFilledButton);