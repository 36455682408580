import {getProductList, setSortState} from 'scenes/dashboard/Dashboard.action';

export default {
    mapStateToProps: (state) => {
        return {
            productList: state.dashboardReducer.productListTab,
            sortParameter: state.dashboardReducer.sortState.sortParameter,
            filterState: state.dashboardReducer.filterState,
            reverseSort: state.dashboardReducer.sortState.reverseSort,
            productType: state.dashboardReducer.filterState.productType
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            getProductList: () => dispatch(getProductList()),
            setSortState: sortState => dispatch(setSortState(sortState)),
        };
    }
};
