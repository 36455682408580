import {getDispoLotSize} from 'scenes/dashboard/Dashboard.action';

export default {
    mapStateToProps: (state) => {
        return {
            dispoTab: state.dashboardReducer.dispoTab
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            getDispoLotSize: () => dispatch(getDispoLotSize()),
        };
    }
};