import React, {Component} from 'react';
import Flex from 'components/grid/Flex';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import connector from './PlannedDelivery.connect';
import DetailsHeader from 'scenes/dashboard/components/DetailsHeader';
import Filter from 'scenes/dashboard/components/Filter';
import DefaultGraphedRow from 'scenes/dashboard/components/DefaultGraphedRow';

export class PlannedDelivery extends Component {

    componentDidMount() {
        this.props.getPlanDeliveryTime();
    }

    render() {
        const {t: translate, planDeliveryTimesTab} = this.props;
        return (
            <Flex item container column padding={5} style={{height: '100%', width: '97%', marginLeft: '1%'}}>
                <Filter refresh={this.props.getPlanDeliveryTime}/>
                <DetailsHeader translate={translate}/>
                <DefaultGraphedRow data={planDeliveryTimesTab?.find(tab => tab.dataRow === 1)} subTitle={'Null'}
                                   title={translate('global.plz-class')}/>
                <DefaultGraphedRow data={planDeliveryTimesTab?.find(tab => tab.dataRow === 2)} subTitle={'(01-10)'}
                                   title={translate('global.plz-class')}/>
                <DefaultGraphedRow data={planDeliveryTimesTab?.find(tab => tab.dataRow === 3)} subTitle={'(11-20)'}
                                   title={translate('global.plz-class')}/>
                <DefaultGraphedRow data={planDeliveryTimesTab?.find(tab => tab.dataRow === 4)} subTitle={'(21-60)'}
                                   title={translate('global.plz-class')}/>
                <DefaultGraphedRow data={planDeliveryTimesTab?.find(tab => tab.dataRow === 5)} subTitle={'60+'}
                                   title={translate('global.plz-class')}/>
            </Flex>
        );
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(
    withTranslation()(
        PlannedDelivery
    )
);
