import _ from 'lodash';

export function getNameInitials(user = {}) {
    const firstLetter = user.givenName ? user.givenName.charAt(0).toUpperCase() : '';
    const secondLetter = user.familyName ? user.familyName.charAt(0).toUpperCase() : '';
    return (firstLetter || secondLetter) ? firstLetter + secondLetter : '?';
}

export function getName(user) {
    const firstName = user.givenName ? user.givenName : '';
    const lastName = user.familyName ? user.familyName : '';
    return (firstName || lastName) ? firstName + ' ' + lastName : '?';
}

export function isAdmin(user){
    const roles = _.get(user, 'roles') || [];
    return roles.includes('ADMIN') || roles.includes('SYSTEM_ADMIN');
}

export const SYSTEM_USER = {
    businessId: '',
    givenName: 'System',
};
