import * as actionTypes from './Main.action.types';
import jwt_decode from "jwt-decode";
import _ from 'lodash';
import moment from 'moment';

export function setSidebarExpanded(isSidebarExpanded){
    return {
        type: actionTypes.SET_SIDEBAR_EXPANDED,
        data: {isSidebarExpanded}
    }
}

export function login(emailAddress, password, verifyCode) {
    return (dispatch) => {
        if (verifyCode) {
            return dispatch({
                type: actionTypes.LOGIN,
                payload: {
                    client: 'auth',
                    request: {
                        url: '/verifyCode',
                        method: 'post',
                        data: {
                            authenticationProvider: 'INTERNAL',
                            emailAddress,
                            verifyCode,
                            origin: 'ADMIN_INTERFACE'
                        }
                    }
                }
            }).then((response) => {
                dispatch(setupAccessData(response.payload.data.accessToken));
                window.localStorage.setItem("accessToken", response.payload.data.accessToken);
                window.localStorage.setItem("mfaToken", response.payload.data.mfaToken);
                const timeStamp = moment().toISOString();
                window.localStorage.setItem('lastTimeStamp', timeStamp);
                return response;
            });
        }

        return dispatch({
            type: actionTypes.LOGIN,
            payload: {
                client: 'auth',
                request: {
                    url: '/login',
                    method: 'post',
                    data: {
                        mfaToken: window.localStorage.getItem("mfaToken"),
                        authenticationProvider: 'INTERNAL',
                        emailAddress,
                        password,
                        origin: 'ADMIN_INTERFACE'
                    }
                }
            }
        }).then((response) => {
            if (response.payload.data.mfaRequired) {
                return response;
            }
            dispatch(setupAccessData(response.payload.data.accessToken));
            window.localStorage.setItem("accessToken", response.payload.data.accessToken);
            const timeStamp = moment().toISOString();
            window.localStorage.setItem('lastTimeStamp', timeStamp);
            return response;
        });
    }
}

export function loadAccessToken() {
    return dispatch => {
        const accessToken = window.localStorage.getItem("accessToken");
        const timeStamp = moment().toISOString();
            window.localStorage.setItem('lastTimeStamp', timeStamp);
        if (accessToken) {
            dispatch(setupAccessData(accessToken));
        }
    }
}

function setupAccessData(accessToken, providerId) {
    return dispatch => {
        const parsedToken = jwt_decode(accessToken);
        const userId = parsedToken.sub;
        const firstName = _.get(parsedToken, 'firstName');
        const lastName = _.get(parsedToken, 'lastName');
        const userName = !firstName && !lastName ? 'as a Guest' : firstName + (firstName ? ' ' : '') + lastName;
        dispatch(fetchCurrentUser());
        dispatch({
            type: actionTypes.LOAD_ACCESS_TOKEN,
            data: {accessToken, userId, userName}
        });
    }
}

export function requestNewPassword(username) {
    const data = {
        emailAddress: username
    }
    return {
        type: actionTypes.REQUEST_NEW_PASSWORD,
        payload: {
            client: 'auth',
            request: {
                method: 'post',
                url: 'user/new-password',
                data
            }
        }
    }
}

export function saveNewPassword(password, token) {
    return {
        type: actionTypes.SAVE_NEW_PASSWORD,
        payload: {
            client: 'auth',
            request: {
                method: 'post',
                url: `user/password-reset/${token}`,
                data: {password}
            }
        }
    }
}

export function logout() {
    window.localStorage.removeItem("accessToken");
    window.location.href = "/";
    return {
        type: actionTypes.LOGOUT,
        payload: {
            client: 'logout',
            request: {
                method: 'post',
            }
        }
    }
}

export function fetchCurrentUser() {
    return {
        type: actionTypes.FETCH_USER,
        payload: {
            client: 'api',
            request: {
                method: 'get',
                url: 'current-user',
            }
        }
    }
}
