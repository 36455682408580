import React, {Component, Fragment} from 'react';
import {Route, Routes, Navigate} from 'react-router';
import {
    LOGOUT,
    LANDING,
    RESET_PASSWORD,
} from 'routes/routes';
import Login from 'scenes/login/Login';
import Logout from 'components/logout/Logout';
import {connect} from 'react-redux';
import connector from './Main.connect';
import _ from 'lodash';
import SessionTimeout from 'components/session-timeout/SessionTimeout';
import Dashboard from 'scenes/dashboard/Dashboard';

export class Main extends Component {

    componentDidMount() {
        this.props.loadAccessToken();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.authenticated && this.props.authenticated) {
            // this.props.setupConfigurations();
        }
    }

    is = role => {
        return (_.get(this.props, 'user.roles') || []).includes(role);
    };

    render() {
        const {authenticated} = this.props;

        return (
            <Fragment>
                <Routes>
                    {
                        authenticated &&
                        <Route path={LANDING.path}>
                            <Route index element={<Dashboard/>}/>
                        </Route>
                    }

                    <Route path={RESET_PASSWORD.path} element={<Login/>}/>
                    <Route path={LOGOUT.path} element={<Logout/>}/>

                    <Route path={'*'} element={
                        authenticated
                            ? <Navigate to={LANDING.path}/>
                            : <Login/>
                    }/>
                </Routes>
                <SessionTimeout/>
            </Fragment>
        )
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(Main);
