import React, { Component } from 'react';
import { Link, FormControl, IconButton, InputAdornment, Card, CardContent, Typography, CardMedia } from "@mui/material";
import { styled } from '@mui/material/styles';
import { VisibilityOff, Visibility } from "@mui/icons-material";
import CustomFormLabel from 'components/input-label/CustomFormLabel';
import CustomTextField from 'components/custom-textfield/CustomTextField';
import CustomFilledButton from 'components/button/CustomFilledButton';
import { transparentBlack054, Secondary } from 'components/colors/Colors';
import Flex from 'components/grid/Flex';
import { withTranslation } from 'react-i18next';
import EpiLogo from 'assets/img/paa-logo.svg';

const ForgotPasswordLink = styled((props) => <Link {...props} />)(({ theme }) => ({
    color: Secondary,
    textTransform: 'inherit',
    padding: 0,
    fontSize: "14px",
    fontWeight: '600',
    marginBottom: "10px"
}));
const FormCard = styled((props) => <Card {...props} />)(({ theme }) => ({
    borderRadius: "16px",
    boxShadow: 'none',
    padding: '22px'
}));
const FormTitle = styled((props) => <Typography {...props} />)(({ theme }) => ({
    fontFamily: 'Inter',
    fontSize: '28px',
    fontWeight: '600',
    lineHeight: '38px',
    marginBottom: '20px'
}));

export class LoginPage extends Component {
    render() {
        const {
            t: translate,
            warning,
            userName,
            password,
            mfa,
            verifyCode,
            handleSubmit,
            onEnter,
            changeState,
            passwordInputRef,
            togglePasswordVisibility,
            isPasswordVisible,
            changeView
        } = this.props;
        return (
            <div style={{ width: "100%", height: "100%", display: 'flex' }}>
                <div style={{ width: '100%', maxWidth: '480px', margin: "auto" }}>
                    <FormCard variant="outlined">
                        <CardContent>
                            <form>
                                <CardMedia component="img" image={EpiLogo} alt="logo" style={{ width: 180, margin: "0 auto 30px" }} />
                                {!mfa && (
                                    <>
                                        <FormTitle component="h3" textAlign="center">{translate('global.welcome-back')}!</FormTitle>
                                        <FormControl margin="normal" required fullWidth>
                                            <CustomFormLabel>{translate('login.username')}</CustomFormLabel>
                                            <CustomTextField
                                                size={'small'}
                                                name={'userName'}
                                                autoFocus
                                                inputProps={{
                                                    autoCapitalize: 'off',
                                                    tabIndex: 1
                                                }}
                                                type="text"
                                                value={userName}
                                                onEnterKey={onEnter}
                                                onChange={changeState}
                                                disabled={mfa}
                                            />
                                        </FormControl>
                                        <FormControl margin="normal" required fullWidth>
                                            <Flex item container justifyContent={'space-between'}>
                                                <CustomFormLabel>{translate('login.password')}</CustomFormLabel>
                                                {mfa ? (<ForgotPasswordLink tabIndex={4} component="button" onClick={() => changeView('LOGIN')}>
                                                    {translate('login.back-login')}...
                                                </ForgotPasswordLink>) : <ForgotPasswordLink tabIndex={4} component="button" onClick={() => changeView('FORGOT')}>
                                                    {translate('login.forgot-password')}...
                                                </ForgotPasswordLink>}
                                            </Flex>
                                            <CustomTextField
                                                name={'password'}
                                                size={'small'}
                                                inputRef={passwordInputRef}
                                                helperText={warning ? translate("login.incorrect") : ''}
                                                error={!!warning}
                                                type={isPasswordVisible ? 'text' : 'password'}
                                                value={password}
                                                inputProps={{
                                                    tabIndex: 2
                                                }}
                                                onEnterKey={handleSubmit}
                                                onChange={changeState}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="Toggle password visibility"
                                                                onClick={togglePasswordVisibility}
                                                            >
                                                                {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>)
                                                }}
                                            />
                                        </FormControl>
                                    </>)}
                                {mfa && (
                                    <>
                                        <FormTitle component="h3" textAlign="center">{translate('login.twofactor-authentication')}!</FormTitle>
                                        <Typography style={{ color: transparentBlack054, marginBottom: 30, fontSize: 14, textAlign: "center" }}>
                                            {translate('login.twofactor-authentication-text')}
                                        </Typography>
                                        <FormControl margin="normal" required fullWidth>
                                            <CustomFormLabel>{translate('login.twofactor-authentication-label')}</CustomFormLabel>
                                            <CustomTextField
                                                size={'small'}
                                                name={'verifyCode'}
                                                helperText={warning ? translate("login.incorrect-verification") : ''}
                                                error={!!warning}
                                                autoFocus
                                                inputProps={{
                                                    autoCapitalize: 'off'
                                                }}
                                                type="text"
                                                onEnterKey={onEnter}
                                                value={verifyCode}
                                                onChange={changeState}
                                                placeholder={translate("login.twofactor-authentication-hint")}
                                            />
                                        </FormControl>
                                    </>
                                )}
                                <FormControl margin="normal" fullWidth>
                                    <CustomFilledButton
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        disabled={!userName || !password}
                                        onClick={handleSubmit}
                                        tabIndex={3}
                                    >
                                        {mfa ? translate('login.twofactor-authentication-btn') : translate('login.login-button')}
                                    </CustomFilledButton>
                                </FormControl>
                            </form>
                        </CardContent>
                    </FormCard>
                </div>
            </div>
        );
    }
}
export default (withTranslation()(LoginPage));