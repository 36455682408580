import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import EpiAlert from './EpiAlert';
import {connect} from "react-redux";
import connector from './EpiErrorAlert.connect';

export class EpiErrorAlert extends Component {

    render() {
        const {open, closeAlert, t: translate} = this.props;

        return (
            <EpiAlert {...{
                style: {justifyContent: 'center', left: 0, right: 0},
                isOpen: open,
                close: closeAlert,
                severity: 'error',
                message: translate('global.backend-call-failed')
            }}/>
        );
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(withTranslation()(EpiErrorAlert));
