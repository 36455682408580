import React, {Component} from 'react';
import {
    Avatar,
    Tooltip
} from "@mui/material";
import _ from 'lodash';
import {getNameInitials} from "domain/User.model";
import {connect} from 'react-redux';
import connector from './HmoAvatar.connect';
import utils from 'utils/Utils';
import * as colors from 'components/colors/Colors';
import {styled} from '@mui/material/styles';
const CustomAvatar = styled((props) => <Avatar {...props} />)(({ theme }) => ({
    width: 30,
    height: 30,
    margin: 10,
    border: `2px solid ${colors.htmlWhite}`,
    '&:hover': {
        border: `2px solid ${colors.linkWater}`,
        cursor: 'pointer'
    }
}));

export const AVATAR_STORE = [];

export class HmoAvatar extends Component {

    state = {
        objectUrl: ''
    }

    componentDidMount() {
        const downloadUrl = _.get(this.props.user, 'avatarDownloadUrl') || _.get(this.props.user, 'avatar.downloadUrl');
        this.mountPicture(downloadUrl);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const downloadUrl = _.get(this.props.user, 'avatarDownloadUrl') || _.get(this.props.user, 'avatar.downloadUrl');
        const previousDownloadUrl = _.get(prevProps.user, 'avatarDownloadUrl') || _.get(prevProps.user, 'avatar.downloadUrl');
        if (downloadUrl && (downloadUrl !== previousDownloadUrl)) {
            this.mountPicture(downloadUrl);
        }
    }

    mountPicture(downloadUrl) {
        const objectUrl = (AVATAR_STORE.find(item => item.downloadUrl === downloadUrl) || {}).objectUrl;
        if (objectUrl) {
            this.setState({objectUrl});
        } else if (downloadUrl) {
            this.mountNewPicture(downloadUrl);
        }
    }

    mountNewPicture(downloadUrl) {
        this.props.downloadFileWithAuthentication(downloadUrl).then(response => {
            const objectUrl = URL.createObjectURL(response.payload.data);
            AVATAR_STORE.push({downloadUrl, objectUrl})
            this.setState({objectUrl});
        });
    }

    render() {
        const {user, style, downloadFileWithAuthentication, placement, small, ...other} = this.props;
        const {objectUrl} = this.state;
        const downloadUrl = _.get(user, 'avatarDownloadUrl') || _.get(user, 'avatar.downloadUrl');
        const nameInitials = getNameInitials(user);

        return (
            <Tooltip
                title={utils.formattedUserName(user)}
                placement={placement || 'bottom-start'}
            >
                <div>
                    {
                        objectUrl
                            ?
                            <CustomAvatar id={`HmoAvatar-${downloadUrl}`}
                                    src={objectUrl}
                                    style={{
                                        width: 30,
                                        height: 30,
                                        ...style}}
                                    {...other}
                            />
                            :
                            <CustomAvatar
                                id={`HmoAvatar-${downloadUrl}`}
                                style={{
                                    width: 30,
                                    height: 30,
                                    backgroundColor: 'grey',
                                    fontSize: 15,
                                    ...style}}
                                {...other}
                            >
                                {nameInitials}
                            </CustomAvatar>
                    }
                </div>
            </Tooltip>
        )
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(HmoAvatar);
