import React, { Component } from 'react';
import {connect} from 'react-redux';
import connector from './Logout.connect';
import {Navigate} from 'react-router';

export class Logout extends Component {

    componentDidMount() {
        this.props.logout();
    }

    render() {
        return (
            <Navigate to={'/'}/>
        );
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(Logout);
