import * as actionTypes from './EpiErrorAlert.action.types';
import {
    SET_FILTER_STATE_FAIL,
    GET_CONFIGURATION_FAIL
} from 'scenes/dashboard/Dashboard.action.types';
import _ from 'lodash';

const initialState = {
    open: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.CLOSE_ALERT: {
            const newState = _.cloneDeep(state);
            newState.open = false;
            return newState;
        }
        case SET_FILTER_STATE_FAIL:
        case GET_CONFIGURATION_FAIL:
        case actionTypes.OPEN_ALERT: {
            const newState = _.cloneDeep(state);
            newState.open = true;
            return newState;
        }
        default:
            return state;
    }
}
