import React, {Component} from 'react';
import Flex from 'components/grid/Flex';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import connector from './Details.connect';
import Filter from 'scenes/dashboard/components/Filter';
import {Typography} from '@mui/material';
import {headerBlue} from 'components/colors/Colors';
import Number from 'components/number/Number';
import {lastTwoTrend} from 'scenes/dashboard/Dashboard.reducer';
import {multiply, _divide} from 'utils/Decimal.util';
import {trendEurColor, trendArticleColor} from 'scenes/dashboard/overview/Overview';
import * as colors from 'components/colors/Colors';
import DetailsHeader from 'scenes/dashboard/components/DetailsHeader';
import GraphedNumberCell from 'scenes/dashboard/components/GraphedNumberCell';
import BorderLinearProgress from 'scenes/dashboard/components/BorderLinearProgress';
import filterConnector from '../components/Filter.connect';

export function trendColorNegativeGood(number) {
    if (number === 0 || number === "0") return colors.alto;
    return number < 0 ? colors.persianGreen : 'red';
}

export function trendColorNegativeBad(number) {
    if (number === 0 || number === "0") return colors.alto;
    return number > 0 ? colors.persianGreen : 'red';
}

const CommonRowData = (props) => {
    const {data, noColor} = props;
    const trendStockInEuro = lastTwoTrend(data?.historicalStockInEuro);
    let rangeProgress = 0;
    if (data?.rangeInWeeks) {
        if (data?.rangeInWeeks > 104) {
            rangeProgress = 100;
        } else {
            rangeProgress = (data?.rangeInWeeks / 104) * 100;
        }
    }
    return (
        <>
            <GraphedNumberCell {...{
                mainNumber: data?.stockInPieces,
                mainStyle: {color: colors.alto},
                bottomStyle: {fontSize: 12, color: colors.alto},
                bottomNumber: lastTwoTrend(data?.historicalStockInPieces),
                graphData: data?.historicalStockInPieces
            }}/>
            <GraphedNumberCell {...{
                mainNumber: data?.stockInEuro,
                currency: true,
                bottomStyle: {fontSize: 12, color: noColor ? colors.alto : trendEurColor(trendStockInEuro)},
                bottomNumber: trendStockInEuro,
                graphData: data?.historicalStockInEuro
            }}/>
            <Flex item={'1 0 14%'} container column style={{padding: '20px 0px'}}
                  justifyContent={'space-between'} alignItems={'center'}>
                <Number value={data?.rangeInWeeks}/>
                <BorderLinearProgress variant="determinate" value={rangeProgress}/>
            </Flex>
            <GraphedNumberCell {...{
                mainNumber: data?.outflowingEuro,
                currency: true,
                mainStyle: {color: colors.alto},
                bottomStyle: {fontSize: 12, color: colors.alto},
                bottomNumber: lastTwoTrend(data?.historicalOutflowingEuro),
                graphData: data?.historicalOutflowingEuro
            }}/>
            <Flex item={'1 0 14%'} container center column>
                <Number value={data?.planStockEndOfYearInEuro} currency/>
                <div style={{width: '100%', height: 50}}/>
            </Flex>
        </>
    );
};

const TLMSRow = ({data, sortiment}) => {
    const articleTrend = lastTwoTrend(data?.historicalNumberOfArticles);
    return (
        <Flex item container grow={0} padding={5} style={{borderBottom: '2px solid grey'}}>
            <Flex item={'1 0 14%'} container center style={{backgroundColor: "#f3f5fd"}}>
                <Typography style={{color: headerBlue, fontSize: 15, fontWeight: 'bold'}}>
                    {sortiment}
                </Typography>
            </Flex>
            <GraphedNumberCell {...{
                mainNumber: data?.numberOfActiveArticles,
                bottomStyle: {fontSize: 12, color: trendArticleColor(articleTrend)},
                bottomNumber: articleTrend,
                graphData: data?.historicalNumberOfArticles
            }}/>
            <CommonRowData data={data}/>
        </Flex>
    );
};

const WithoutStockRow = (props) => {
    const {translate, data, previousRowArticles, onClick} = props;
    const bigNumber = previousRowArticles && data?.numberOfActiveArticles
        ? multiply(_divide(data?.numberOfActiveArticles, previousRowArticles), 100)
        : 0;
    const articleTrend = lastTwoTrend(data?.historicalNumberOfArticles);

    return (
        <Flex item container grow={0} padding={5} style={{borderBottom: '1px solid lightgrey'}}>
            <Flex item={'1 0 14%'} container center style={{backgroundColor: "#f3f5fd"}}>
                <Typography style={{color: headerBlue, fontSize: 15, fontWeight: 'bold'}}>
                    {translate('global.ohne-bestand')}
                </Typography>
            </Flex>
            <GraphedNumberCell {...{
                mainNumber: bigNumber,
                mainStyle: {fontSize: 36, color: 'red'},
                percent: true,
                bottomStyle: {fontSize: 12, color: trendArticleColor(articleTrend)},
                bottomNumber: articleTrend,
                middleNumber: data?.numberOfActiveArticles,
                graphData: data?.historicalNumberOfArticles,
                onClick
            }}/>
            <GraphedNumberCell/>
            <GraphedNumberCell/>
            <Flex item={'1 0 14%'} container column style={{padding: '20px 0px'}}
                  justifyContent={'space-between'} alignItems={'center'}>
            </Flex>
            <GraphedNumberCell {...{
                mainNumber: data?.outflowingEuro,
                currency: true,
                mainStyle: {color: colors.alto},
                bottomStyle: {fontSize: 12, color: colors.alto},
                bottomNumber: lastTwoTrend(data?.historicalOutflowingEuro),
                graphData: data?.historicalOutflowingEuro
            }}/>
            <Flex item={'1 0 14%'} container center column>
                <Number value={data?.planStockEndOfYearInEuro} currency/>
                <div style={{width: '100%', height: 50}}/>
            </Flex>
        </Flex>
    );
};

const Available100Row = (props) => {
    const {translate, data, previousRowArticles} = props;
    const bigNumber = previousRowArticles && data?.numberOfActiveArticles
        ? multiply(_divide(data?.numberOfActiveArticles, previousRowArticles), 100)
        : 0;
    const articleTrend = lastTwoTrend(data?.historicalNumberOfArticles);
    return (
        <Flex item container grow={0} padding={5} style={{borderBottom: '1px solid lightgrey'}}>
            <Flex item={'1 0 14%'} container center style={{backgroundColor: "#f3f5fd"}}>
                <Typography style={{color: headerBlue, fontSize: 15, fontWeight: 'bold'}}>
                    100% {translate('global.availability')}
                </Typography>
            </Flex>
            <GraphedNumberCell {...{
                mainNumber: bigNumber,
                mainStyle: {fontSize: 36, color: colors.persianGreen},
                percent: true,
                middleNumber: data?.numberOfActiveArticles,
                bottomStyle: {fontSize: 12, color: trendArticleColor(articleTrend)},
                bottomNumber: articleTrend,
                graphData: data?.historicalNumberOfArticles
            }}/>
            <CommonRowData data={data}/>
        </Flex>
    );
};

const AvailableMoreThan100Row = (props) => {
    const {translate, data, previousRowArticles} = props;
    const bigNumber = previousRowArticles && data?.numberOfActiveArticles
        ? multiply(_divide(data?.numberOfActiveArticles, previousRowArticles), 100)
        : 0;
    const articleTrend = lastTwoTrend(data?.historicalNumberOfArticles);
    return (
        <Flex item container grow={0} padding={5} style={{borderBottom: '2px solid grey'}}>
            <Flex item={'1 0 14%'} container center style={{backgroundColor: "#f3f5fd"}}>
                <Typography style={{color: headerBlue, fontSize: 15, fontWeight: 'bold'}}>
                    {'<'}100% {' ' + translate('global.availability')}
                </Typography>
            </Flex>
            <GraphedNumberCell {...{
                mainNumber: bigNumber,
                mainStyle: {fontSize: 36, color: 'orange'},
                percent: true,
                middleNumber: data?.numberOfActiveArticles,
                bottomStyle: {fontSize: 12, color: trendArticleColor(articleTrend)},
                bottomNumber: articleTrend,
                graphData: data?.historicalNumberOfArticles,
            }}/>
            <CommonRowData data={data}/>
        </Flex>
    );
};

const Available90DaysMoreThan100Row = (props) => {
    const {translate, data, onClick} = props;
    const articleTrend = lastTwoTrend(data?.historicalNumberOfArticles);
    return (
        <Flex item container grow={0} padding={5} style={{borderBottom: '1px solid lightgrey'}}>
            <Flex item={'1 0 14%'} container center style={{backgroundColor: "#f3f5fd"}} column>
                <Typography style={{color: headerBlue, fontSize: 15, fontWeight: 'bold'}}>
                    {'<'}100% {' ' + translate('global.availability')}
                </Typography>
                <Typography style={{color: headerBlue, fontSize: 12}}>
                    {translate('global.gap-in-90-day')}
                </Typography>
            </Flex>
            <GraphedNumberCell {...{
                mainNumber: data?.numberOfActiveArticles,
                mainStyle: {
                    color: 'blue',
                    cursor: 'pointer',
                    textDecoration: 'underline'
                },
                bottomStyle: {fontSize: 12, color: trendArticleColor(articleTrend)},
                bottomNumber: articleTrend,
                graphData: data?.historicalNumberOfArticles,
                onClick
            }}/>
            <CommonRowData data={data}/>
        </Flex>
    );
};

const MABSTRow = (props) => {
    const {translate, data} = props;
    const articleTrend = lastTwoTrend(data?.historicalNumberOfArticles);
    return (
        <Flex item container grow={0} padding={5} style={{borderBottom: '1px solid lightgrey'}}>
            <Flex item={'1 0 14%'} container center style={{backgroundColor: "#f3f5fd"}} column>
                <Typography style={{color: headerBlue, fontSize: 15, fontWeight: 'bold'}}>
                    {translate('global.mabst-stock')}
                </Typography>
                <Typography style={{color: headerBlue, fontSize: 12}}>
                    {translate('global.larger-stock')}
                </Typography>
            </Flex>
            <GraphedNumberCell {...{
                mainNumber: data?.numberOfActiveArticles,
                bottomStyle: {fontSize: 12, color: colors.alto},
                bottomNumber: articleTrend,
                graphData: data?.historicalNumberOfArticles
            }}/>
            <CommonRowData data={data} noColor/>
        </Flex>
    );
};

const TOPRow = (props) => {
    const {translate, data} = props;
    const articleTrend = lastTwoTrend(data?.historicalNumberOfArticles);
    return (
        <Flex item container grow={0} padding={5} style={{borderBottom: '1px solid lightgrey'}}>
            <Flex item={'1 0 14%'} container center style={{backgroundColor: "#f3f5fd"}} column>
                <Typography style={{color: headerBlue, fontSize: 15, fontWeight: 'bold'}}>
                    {translate('global.top-inventory')}
                </Typography>
                <Typography style={{color: headerBlue, fontSize: 12}}>
                    {translate('global.larger-eur-7500')}
                </Typography>
            </Flex>
            <GraphedNumberCell {...{
                mainNumber: data?.numberOfActiveArticles,
                bottomStyle: {fontSize: 12, color: colors.alto},
                bottomNumber: articleTrend,
                graphData: data?.historicalNumberOfArticles
            }}/>
            <CommonRowData data={data} noColor/>
        </Flex>
    );
};

const Monate24 = (props) => {
    const {translate, data} = props;
    const articleTrend = lastTwoTrend(data?.historicalNumberOfArticles);
    return (
        <Flex item container grow={0} padding={5} style={{borderBottom: '1px solid lightgrey'}}>
            <Flex item={'1 0 14%'} container center style={{backgroundColor: "#f3f5fd"}} column>
                <Typography style={{color: headerBlue, fontSize: 15, fontWeight: 'bold', textAlign: 'center'}}>
                    {translate('global.24-Monate')}
                </Typography>
            </Flex>
            <GraphedNumberCell {...{
                mainNumber: data?.numberOfActiveArticles,
                bottomStyle: {fontSize: 12, color: colors.alto},
                bottomNumber: articleTrend,
                graphData: data?.historicalNumberOfArticles
            }}/>
            <CommonRowData data={data} noColor/>
        </Flex>
    );
};

const PlanBestands = (props) => {
    const {translate, data} = props;
    const articleTrend = lastTwoTrend(data?.historicalNumberOfArticles);
    return (
        <Flex item container grow={0} padding={5} style={{borderBottom: '1px solid lightgrey'}}>
            <Flex item={'1 0 14%'} container center style={{backgroundColor: "#f3f5fd"}} column>
                <Typography style={{color: headerBlue, fontSize: 15, fontWeight: 'bold' , textAlign: 'center'}}>
                    {translate('global.plan-bestands-risiko-artikel')}
                </Typography>
                <Typography style={{color: headerBlue, fontSize: 12 , textAlign: 'center'}}>
                    {translate('global.Jahresend-Planbestand')}
                </Typography>
            </Flex>
            <GraphedNumberCell {...{
                mainNumber: data?.numberOfActiveArticles,
                bottomStyle: {fontSize: 12, color: colors.alto},
                bottomNumber: articleTrend,
                graphData: data?.historicalNumberOfArticles
            }}/>
            <CommonRowData data={data} noColor/>
        </Flex>
    );
};

export class Details extends Component {

    componentDidMount() {
        this.props.getDetails();
    }

    goToProductList = productType => event => {
        this.props.setFilterState({
            ...this.props.filterState,
            productType
        });
        this.props.changeTab('PRODUCT_LIST');
    }

    render() {
        const {t: translate, detailsTab, filterState} = this.props;
        const {goToProductList} = this;
        const tlmsRow = detailsTab?.find(tab => tab.dataRow === 1);
        const withoutStockRow = detailsTab?.find(tab => tab.dataRow === 2);
        const available100Row = detailsTab?.find(tab => tab.dataRow === 3);
        const sortiment = filterState.sortiment ?? '';
        return (
            <Flex item container column padding={5} style={{height: '100%', width: '97%', marginLeft: '1%'}}>
                <Filter refresh={this.props.getDetails}/>
                <DetailsHeader translate={translate}/>
                <TLMSRow sortiment={sortiment} data={tlmsRow}/>
                <WithoutStockRow translate={translate}
                                 onClick={goToProductList('WITHOUT_STOCK')}
                                 previousRowArticles={tlmsRow?.numberOfActiveArticles}
                                 data={withoutStockRow}/>
                <Available100Row translate={translate}
                                 previousRowArticles={tlmsRow?.numberOfActiveArticles}
                                 data={available100Row}/>
                <AvailableMoreThan100Row translate={translate}
                                         previousRowArticles={tlmsRow?.numberOfActiveArticles}
                                         data={detailsTab?.find(tab => tab.dataRow === 4)}/>
                <Available90DaysMoreThan100Row translate={translate}
                                               data={detailsTab?.find(tab => tab.dataRow === 5)}
                                               onClick={goToProductList('DAY90')}
                />
                <MABSTRow translate={translate} data={detailsTab?.find(tab => tab.dataRow === 6)}/>
                <TOPRow translate={translate} data={detailsTab?.find(tab => tab.dataRow === 7)}/>
                <Monate24 translate={translate} data={detailsTab?.find(tab => tab.dataRow === 8)}/>
                <PlanBestands translate={translate} data={detailsTab?.find(tab => tab.dataRow === 9)}/>
            </Flex>
        );
    }
}

export default connect(filterConnector.mapStateToProps, filterConnector.mapDispatchToProps)(connect(connector.mapStateToProps, connector.mapDispatchToProps)(
    withTranslation()(
        Details
    )
));
