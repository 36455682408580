import {getConfiguration} from './Dashboard.action'

export default {
    mapStateToProps: (state) => {
        return {
            isSidebarExpanded: state.mainReducer.isSidebarExpanded,
            selectedDate: state.dashboardReducer.filterState.date
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            getConfiguration: () => dispatch(getConfiguration()),
        };
    }
};
