import * as actionTypes from './Dashboard.action.types';
import _ from 'lodash';
import {divide, _multiply, _subtract} from 'utils/Decimal.util';
import utils from 'utils/Utils';

const initialState = {
    filterState: {
        date: '',
        sortiment: 'TLMS',
        productType: 'DAY90',
        productionSite: 'ALL',
        marcDispo: 'ALL',
        marcDismm: 'ALL',
        marcDisls: 'ALL'
    },
    filterOptions: {
        dates: [],
        sortiments: [],
        productTypes: [],
        productionSites: [],
        marcDispos: [],
        marcDismms: [],
        marcDislss: []
    },
    sortState: {
        sortParameter: '',
        reverseSort: false
    },
    overviewTab: [],
    detailsTab: [],
    planDeliveryTimesTab: [],
    dispoTab: [],
    graphsTab: [],
    productListTab: [],
    tabsFromBackend: {
        overviewTab: [],
        detailsTab: [],
        planDeliveryTimesTab: [],
        dispoTab: [],
        graphsTab: [],
        productListTab: [],
    }
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.GET_CONFIGURATION_SUCCESS: {
            const newState = _.cloneDeep(state);
            newState.filterOptions = action.payload.data;

            newState.filterState.date = newState.filterOptions.dates[0];
            newState.filterState.sortiment = newState.filterOptions.sortiments[0];
            newState.filterState.productTypes = newState.filterOptions.productTypes[0];
            return newState;
        }
        case actionTypes.SET_DATE: {
            const newState = _.cloneDeep(state);
            newState.filterState.date = action.data;
            return newState;
        }
        case actionTypes.SET_SORT_STATE: {
            const newState = {...state};
            const sortState = _.get(action, 'data.sortState', {});
            newState.sortState = sortState;
            newState.productListTab = newState.productListTab.sort(by(sortState));
            return newState;
        }
        case actionTypes.SET_FILTER_STATE: {
            const newState = _.cloneDeep(state);
            newState.filterState = action.data;
            if(newState.filterState.sortiment !== state.filterState.sortiment){
                newState.overviewTab = mapOverview(state.tabsFromBackend.overviewTab.filter(filterBySortiment(newState)));
                newState.detailsTab = state.tabsFromBackend.detailsTab.filter(filterBySortiment(newState));
                newState.planDeliveryTimesTab = state.tabsFromBackend.planDeliveryTimesTab.filter(filterBySortiment(newState));
                newState.dispoTab = state.tabsFromBackend.dispoTab.filter(filterBySortiment(newState));
                newState.graphsTab = state.tabsFromBackend.graphsTab.filter(filterBySortiment(newState));
            }
            newState.productListTab = applyProductListFilters(state.tabsFromBackend.productListTab, newState);
            return newState;
        }
        case actionTypes.GET_OVERVIEW_SUCCESS: {
            const newState = _.cloneDeep(state);
            newState.overviewTab = mapOverview(action.payload.data.filter(filterBySortiment(state)));
            newState.tabsFromBackend = {
                ...state.tabsFromBackend,
                overviewTab: action.payload.data
            };
            return newState;
        }
        case actionTypes.GET_DETAILS_SUCCESS: {
            const newState = _.cloneDeep(state);
            newState.detailsTab = action.payload.data.filter(filterBySortiment(state));
            newState.tabsFromBackend = {
                ...state.tabsFromBackend,
                detailsTab: action.payload.data
            };
            return newState;
        }
        case actionTypes.GET_PLAN_DELIVERY_TIMES_SUCCESS: {
            const newState = _.cloneDeep(state);
            newState.planDeliveryTimesTab = action.payload.data.filter(filterBySortiment(state));
            newState.tabsFromBackend = {
                ...state.tabsFromBackend,
                planDeliveryTimesTab: action.payload.data
            };
            return newState;
        }
        case actionTypes.GET_DISPO_SUCCESS: {
            const newState = _.cloneDeep(state);
            newState.dispoTab = action.payload.data.filter(filterBySortiment(state));
            newState.tabsFromBackend = {
                ...state.tabsFromBackend,
                dispoTab: action.payload.data
            };
            return newState;
        }
        case actionTypes.GET_GRAPHS_SUCCESS: {
            const newState = _.cloneDeep(state);
            newState.graphsTab = action.payload.data.filter(filterBySortiment(state));
            newState.tabsFromBackend = {
                ...state.tabsFromBackend,
                graphsTab: action.payload.data
            };
            return newState;
        }
        case actionTypes.GET_PRODUCT_LIST_SUCCESS: {
            const newState = _.cloneDeep(state);
            newState.productListTab = action.payload.data
                .filter(filterBySortiment(state))
                .filter(filterByProductType(state));
            newState.tabsFromBackend = {
                ...state.tabsFromBackend,
                productListTab: action.payload.data
            };
            return newState;
        }
        default:
            return state;
    }
}

function applyProductListFilters(productList, reducerState){
    return productList.filter(filterBySortiment(reducerState))
        .filter(filterByProductType(reducerState))
        .filter(filterByProductionSite(reducerState))
        .filter(filterByMarcDispo(reducerState))
        .filter(filterByMarcDismm(reducerState))
        .filter(filterByMarcDisls(reducerState))
        .sort(by(reducerState.sortState));
}

function filterBySortiment(state) {
    return item => {
        return item.sortiment === state.filterState.sortiment;
    }
}

function filterByProductType(state) {
    return item => {
        return item.type === state.filterState.productType;
    }
}

function filterByProductionSite(state) {
    return item => {
        return state.filterState.productionSite === 'ALL' || item.werk === state.filterState.productionSite;
    }
}

function filterByMarcDispo(state) {
    return item => {
        return state.filterState.marcDispo === 'ALL' || item.marcDispo === state.filterState.marcDispo;
    }
}

function filterByMarcDismm(state) {
    return item => {
        return state.filterState.marcDismm === 'ALL' || item.marcDismm === state.filterState.marcDismm;
    }
}

function filterByMarcDisls(state) {
    return item => {
        return state.filterState.marcDisls === 'ALL' || item.marcDisls === state.filterState.marcDisls;
    }
}

function mapOverview(overviewFromBackend) {
    const row1tab2 = overviewFromBackend.find(rowTab => rowTab.dataTab === 2 && rowTab.dataRow === 1);
    const stock = row1tab2.numberOfActiveArticles;
    const stockHistory = row1tab2.historicalNumberOfArticles;
    const stockTrends = overviewTrends(stockHistory);

    const stockInEUR = row1tab2.stockInEuro;
    const stockEURHistory = row1tab2.historicalStockInEuro;
    const stockEURTrend = overviewTrends(stockEURHistory);

    return {
        numberOfArticle: {
            value: stock,
            history: stockHistory.map(s => ({value: s.value, date: utils.toDateOrDefault(s.date)})),
            lastTwoTrend: stockTrends.lastTwoTrend,
            highestTrend: stockTrends.highestTrend,
            lowestTrend: stockTrends.lowestTrend,
            min: _.minBy(stockHistory, e => e.value),
            max: _.maxBy(stockHistory, e => e.value)
        },
        stockInEUR: {
            value: stockInEUR,
            history: stockEURHistory.map(s => ({value: s.value, date:utils.toDateOrDefault(s.date)})),
            lastTwoTrend: stockEURTrend.lastTwoTrend,
            highestTrend: stockEURTrend.highestTrend,
            lowestTrend: stockEURTrend.lowestTrend,
            min: _.minBy(stockEURHistory, e => e.value),
            max: _.maxBy(stockEURHistory, e => e.value)
        }
    }
}

function overviewTrends(array) {
    const last = array[array.length - 1]?.value;
    const highest = _.maxBy(array, e => e.value)?.value;
    const lowest = _.minBy(array, e => e.value)?.value;
    const lastTwoTrend = array.length > 1 && last !== 0
        ? _multiply(divide(_subtract(last, array[array.length - 2]?.value), last), 100)
        : 0;
    const highestTrend = array.length > 1 && last !== 0
        ? _multiply(divide(_subtract(last, highest), last), 100)
        : 0;
    const lowestTrend = array.length > 1 && last !== 0
        ? _multiply(divide(_subtract(last, lowest), last), 100)
        : 0;
    return {
        lastTwoTrend,
        highestTrend,
        lowestTrend
    }
}

export function lastTwoTrend(array) {
    if (!array || array.length < 2 || array[array.length - 1].value === 0) return 0;
    const last = array[array.length - 1].value;
    return _multiply(divide(_subtract(last, array[array.length - 2].value),last), 100);
}

function by(sortState) {
    return (a, b) => {
        const sortParameter = sortState.sortParameter;
        const reverseSort = sortState.reverseSort;
        if (_.get(a, sortParameter) > _.get(b, sortParameter)) {
            return reverseSort ? 1 : -1;
        } else if (_.get(a, sortParameter) < _.get(b, sortParameter)) {
            return reverseSort ? -1 : 1;
        } else {
            return 0;
        }
    };
}
