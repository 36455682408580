import {getGraphs} from 'scenes/dashboard/Dashboard.action';

export default {
    mapStateToProps: (state) => {
        return {
            graphsTab: state.dashboardReducer.graphsTab
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            getGraphs: () => dispatch(getGraphs()),
        };
    }
};