import {getDetails, setFilterState} from 'scenes/dashboard/Dashboard.action';

export default {
    mapStateToProps: (state) => {
        return {
            detailsTab: state.dashboardReducer.detailsTab,
            filterState: state.dashboardReducer.filterState
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            getDetails: () => dispatch(getDetails()),
            setFilterState: filterState => dispatch(setFilterState(filterState))
        };
    }
};
