import {downloadFileWithAuthentication} from 'scenes/FileHandler.action';

export default {
    mapStateToProps: (state) => {
        return {
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            downloadFileWithAuthentication: url => dispatch(downloadFileWithAuthentication(url)),
        };
    }
};
