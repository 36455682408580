import {CLOSE_ALERT, OPEN_ALERT} from './EpiErrorAlert.action.types';

export function closeAlert(){
    return {
        type: CLOSE_ALERT
    }
}

export function openAlert(){
    return {
        type: OPEN_ALERT
    }
}
