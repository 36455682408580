import React, {Component} from 'react';
import Flex from 'components/grid/Flex';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import connector from './Graphics.connect';
import Filter from 'scenes/dashboard/components/Filter';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {Typography, Paper} from '@mui/material';
import {styled} from '@mui/material/styles';

const FloatTextContainer = styled((props) => <Paper elevation={3} {...props} />)(({theme}) => ({
    position: 'absolute',
    right: '30%',
    bottom: '30%',
    width: 200,
    padding: 10,
    display: 'flex',
}));

const GraphImage = (props) => {
    const {data, hover, onMouseEnter, onMouseLeave} = props;
    return (
        <Flex item container column center>
                <Typography style={{color: 'grey', fontSize: 15, marginBottom: 10}}>{data?.title}</Typography>
                <img alt={'data?.title'} src={data?.image} style={{objectFit: 'cover', width: '70%'}}
                     onMouseEnter={onMouseEnter}
                     onMouseLeave={onMouseLeave}/>
                {
                    data?.hoverText && hover &&
                    <FloatTextContainer>
                        <Typography style={{color: 'grey', fontSize: 13}}>{data?.hoverText}</Typography>
                    </FloatTextContainer>
                }
        </Flex>
    );
};

export class Graphics extends Component {

    state = {
        currentPage: 0
    }

    stepBack = () => {
        this.setState({
            currentPage: this.state.currentPage - 1
        })
    }

    stepForward = () => {
        this.setState({
            currentPage: this.state.currentPage + 1
        })
    }

    onMouseEnter = event => {
        this.setState({hover: true});
    }

    onMouseLeave = event => {
        if(!event?.nativeEvent?.relatedTarget?.className?.includes('Paper')){
            this.setState({hover: false});
        }
    }

    componentDidMount() {
        this.props.getGraphs();
    }

    render() {
        const {graphsTab} = this.props;
        const {currentPage, hover} = this.state;
        const {stepBack, stepForward, onMouseEnter, onMouseLeave} = this;
        return (
            <Flex item container column padding={5} style={{height: '100%'}}>
                <Filter refresh={this.props.getGraphs}/>
                <Flex item container>
                    {
                        currentPage !== 0 &&
                        <Flex item={'0 0 0'} container onClick={stepBack} center style={{marginLeft: 20}}>
                            <ArrowBackIosIcon/>
                        </Flex>
                    }
                    {
                        <GraphImage {...{
                            data: graphsTab[currentPage],
                            hover,
                            onMouseEnter,
                            onMouseLeave,
                        }}/>
                    }
                    {
                        currentPage !== graphsTab.length - 1 &&
                        <Flex item={'0 0 0'} container onClick={stepForward} center style={{marginRight: 20}}>
                            <ArrowForwardIosIcon/>
                        </Flex>
                    }
                </Flex>
            </Flex>
        );
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(
    withTranslation()(
        Graphics
    )
);
