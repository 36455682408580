import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import store from 'store/Store';
import Main from 'scenes/Main';
import 'i18n/i18n';
import reportWebVitals from './reportWebVitals';
import {createTheme, ThemeProvider} from '@mui/material/styles';

const defaultTheme = createTheme();
const Loader = () => (
    <div>Loading...</div>
);


const root = ReactDOM.createRoot(document.getElementById('ida-frontend-root'));
root.render(
    <Provider store={store}>
        <Suspense fallback={<Loader/>}>
            <BrowserRouter>
                <ThemeProvider theme={defaultTheme}>
                    <Main/>
                </ThemeProvider>
            </BrowserRouter>
        </Suspense>
    </Provider>
);

reportWebVitals();
