import React, {Component} from 'react';
import _ from 'lodash';

const DEFAULT_GROW = '1';
const DEFAULT_SHRINK = '1';
const DEFAULT_BASIS = 'auto';
const defaultOrAsString = (defaultValue, value) => (value === undefined || value === true ? defaultValue : value.toString());

export const correctItem = item => {
    const itemSplitted = _.split(item, ' ');
    const isBasisZero = itemSplitted.length === 3 && itemSplitted[2] === '0';
    return isBasisZero ? `${itemSplitted[0]} ${itemSplitted[1]} ${'0%'}` : item;
};

export const correctBasis = basis => {
    return (basis === 0) || (basis === '0') ? '0%' : basis;
};

/**
 * container: means "display: flex" style. Takes no argument.
 * item: means "flex: '1 1 auto'" without argument. Takes argument which will be used as is: "flex: '<argument>'"
 *      if items argument is present, the 'grow', 'shrink' and 'basis' properties are ignored.
 * grow, shrink, basis: means the '1' '1' and 'auto' respectively without argument. Takes argument which will be used as string.
 *      if there is no item property (or it has an argument) the 'grow', 'shrink' and 'basis' properties are ignored.
 */
export class Flex extends Component {
    render() {
        let {className, style, container, continer, item, grow, shrink, basis, direction, justifyContent, alignItems, wrap,
            alignContent, fullWidth, padding, divElementRef, column, center, spaceBetween, ...other} = this.props;
        const flexGrow = defaultOrAsString(DEFAULT_GROW, grow);
        const flexShrink = defaultOrAsString(DEFAULT_SHRINK, shrink);
        const flexBasis = defaultOrAsString(DEFAULT_BASIS, correctBasis(basis));
        const flex = item === true ? `${flexGrow} ${flexShrink} ${flexBasis}` : correctItem(item);
        const flexDirection = column
            ? 'column'
            : direction;
        if (center) {
            justifyContent = 'center';
            alignItems = 'center';
            alignContent = 'center';
        }

        if(spaceBetween){
            justifyContent = 'space-between';
        }

        return (
            <div
                style={{
                    ...((container || continer) && {display: 'flex'}),
                    ...(wrap && {flexWrap: 'wrap'}),
                    ...(flex && {flex}),
                    ...(flexDirection && {flexDirection}),
                    ...(justifyContent && {justifyContent}),
                    ...(alignItems && {alignItems}),
                    ...(alignContent && {alignContent}),
                    ...(fullWidth && {width: '100%'}),
                    ...(padding && {padding}),
                    ...style
                }}
                className={className}
                ref={divElementRef}
                {...other}
            >
                {this.props.children}
            </div>
        );
    }
}

export default Flex;
