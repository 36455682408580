import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {
    IconButton,
    Tooltip,
} from '@mui/material';
import {htmlWhite, headerBlue, catskillWhite} from 'components/colors/Colors';
import Flex from 'components/grid/Flex';
import {LANDING} from 'routes/routes';
import {Trans, withTranslation} from 'react-i18next';
import Profile from 'components/profile/Profile';
import {ReactComponent as HomeIcon} from 'assets/img/dashboard.svg';
import {styled} from '@mui/material/styles';
const Container = styled((props) => <Flex {...props} />)(({ theme }) => ({
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    backgroundColor: headerBlue,
    height: '100%',
    top: 0,
    left: 0,
    width: 70,
    maxWidth: 70,
    zIndex: 11,
    borderRight: `1px solid ${catskillWhite}`
}));
const ListItemContainer = styled((props) => <Flex {...props} />)(({ theme, route }) => ({
    flex: '0 1 70px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
        background: `linear-gradient(90deg, #DBBE37 10%, transparent 0%)`,
    },
    marginLeft: 1,
    ...(route.path === window.location.pathname && {
        background: `linear-gradient(90deg, white 10%, transparent 0%)`
    })
}));

export const SIDEBAR_EXPANDED_WIDTH = 200;
export const SIDEBAR_SHRANK_WIDTH = 70;

export const styles = theme => ({
    active: {
        background: `linear-gradient(90deg, white 10%, transparent 0%)`,
    },
    icon: {
        width: 25,
        height: 25,
        color: htmlWhite,
        fill: htmlWhite
    },
    hover: {

    },
    paperAnchorDockedLeft: {
        border: 'unset'
    },
    dividerContainer: {
        cursor: 'col-resize',
        transition: 'opacity 200ms ease 0s',
        opacity: 0,
        '&:hover': {
            opacity: 1
        }
    },
    divider: {
        width: 2,
        maxWidth: 2,
        backgroundColor: 'rgb(76, 154, 255)',
        height: '100%'
    },
    expandIcon: {
        transform: 'rotate(90deg)'
    },
    shrinkIcon: {
        transform: 'rotate(-90deg)'
    },
    footerDivider: {
        backgroundColor: catskillWhite,
        height: 2,
        marginLeft: 10
    }
});

const SidebarListItem = (props) => {
    const {route} = props;
    return (
        <ListItemContainer route={route}>
            <Tooltip
                placement="right-start"
                disableFocusListener
                title={<Trans i18nKey={route.subtitle}/>}>
                <IconButton component={Link}
                            style={{padding: 0}}
                            onClick={() => {
                            }}
                            to={route.path}>
                    <route.icon style={{
                        width: 25,
                        height: 25,
                        color: htmlWhite,
                        fill: htmlWhite
                    }}/>
                </IconButton>
            </Tooltip>
        </ListItemContainer>
    );
};

const Footer = () => {
    return <Flex container direction={'column'} alignItems={'center'}>
        <Profile/>
    </Flex>
};

export class Sidebar extends Component {

    render() {
        return (
            <Container>
                <Flex item={'0 1 60px'} container justifyContent={'center'} padding={10}>
                    <HomeIcon style={{border: 'solid 2px white', borderRadius: 6}}/>
                </Flex>
                <SidebarListItem route={LANDING}/>
                <Flex item={'1'} container justifyContent={'center'} direction={'column'}/>
                <Flex item grow={0} container style={{marginRight: 10,
                          backgroundColor: catskillWhite,
                          height: 2,
                          marginLeft: 10}}/>
                <Footer/>
            </Container>
        );
    }
}

export default withTranslation()(Sidebar);
