import React from 'react';
import Flex from 'components/grid/Flex';
import Number from 'components/number/Number';
import EurValueAreaChart from 'scenes/dashboard/overview/EurValueAreaChart';
import {alto} from 'components/colors/Colors';
import utils from 'utils/Utils';

const GraphedNumberCell = (props) => {
    const {
        mainNumber, mainStyle, bottomStyle, middleNumber, bottomNumber, graphData, currency, percent, onClick
    } = props;
    const newGraph = graphData?.map(g => ({...g, date: utils.toDateOrDefault(g.date)}))
    const formatter = currency
        ? (value, name, props) => [utils.currencyFormatter.format(value), "Value"]
        : (value, name, props) => [utils.numberFormatter.format(value), "Value"];
    return (
        <Flex item={'1 0 14%'} container center column>
            {
                mainNumber ?
                    <>
                        <Flex item container>
                            <Flex item={'1 0 70%'} container justifyContent={'flex-end'} padding={5}>
                                <Number value={mainNumber} currency={currency} style={mainStyle} percent={percent}
                                        {...{...(!middleNumber && onClick && {onClick})}}
                                />
                            </Flex>
                            <Flex container justifyContent={'flex-end'} padding={5} column>
                                {
                                    middleNumber &&
                                    <Number value={middleNumber} style={{
                                        fontSize: 13,
                                        color: middleNumber && onClick ? 'blue' : 'grey',
                                        cursor: middleNumber && onClick ? 'pointer' : 'unset',
                                        textDecoration: middleNumber && onClick ? 'underline' : 'unset'
                                    }} {...{...(middleNumber && onClick && {onClick})}}/>
                                }
                                <Number value={bottomNumber} percent
                                        style={bottomStyle || {fontSize: 12, color: alto}}/>
                            </Flex>
                        </Flex>
                        <div style={{width: '90%', height: 46}}>
                            <EurValueAreaChart data={newGraph} formatter={formatter}/>
                        </div>

                    </>
                    :
                    <Flex item={'1 0 70%'} container justifyContent={'flex-end'} padding={5}>
                        <Number value={mainNumber} currency={currency} style={mainStyle} percent={percent}/>
                    </Flex>
            }
        </Flex>
    );
};

export default GraphedNumberCell;
