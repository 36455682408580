export function downloadFileWithAuthentication(url) {
    return {
        type: '@file/DOWNLOAD_FILE',
        payload: {
            client: 'rest',
            request: {
                url,
                method: 'get',
                responseType: 'blob',
            }
        }
    }
}

