import {combineReducers} from 'redux';
import mainReducer from 'scenes/Main.reducer';
import dashboardReducer from 'scenes/dashboard/Dashboard.reducer';
import alertReducer from 'components/alert/EpiErrorAlert.reducer';

export default combineReducers({
    mainReducer,
    dashboardReducer,
    alertReducer
});
