import _ from 'lodash';
import {logout} from 'scenes/Main.action';
import {FETCH_USER, LOGIN} from 'scenes/Main.action.types';

export const interceptors = {
    request: [
        (getState, config) => {
            const mainReducer = getState.getState().mainReducer;
            const sourceAction = _.get(config, 'reduxSourceAction.type');
            const accessToken = _.get(mainReducer, 'accessToken');
            if (accessToken && sourceAction !== LOGIN) {
                config.headers['Authorization'] = 'Bearer ' + accessToken;
            }
            return config;
        }
    ],
    response: [{
        success: function ({getState, dispatch, getSourceAction}, res) {
            if (res && res.data && res.data.hasOwnProperty('errors')) {
                return Promise.reject(res);
            } else {
                return res;
            }
        },
        error: function ({getState, dispatch, getSourceAction}, error) {
            const errorCode = _.get(error, 'response.status');
            const sourceAction = _.get(error, 'response.config.reduxSourceAction.type');
            if ((errorCode === 401) && sourceAction !== LOGIN && sourceAction !== FETCH_USER) {
                console.log('User is no longer Authenticated. Enforcing logout.');
                dispatch(logout());
            }
            return Promise.reject(error);
        }
    }]
};
