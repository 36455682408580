import React, {Component} from 'react';
import {
    Snackbar,
    IconButton,
    Alert
} from "@mui/material";
import {
    Close as CloseIcon
} from '@mui/icons-material';

export class EpiAlert extends Component {

    render() {
        const {isOpen, close, severity, message, style, classes} = this.props;

        return (
            <Snackbar open={isOpen} autoHideDuration={6000} onClose={close} style={style}>
                <Alert severity={severity}
                       action={
                           <IconButton style={{padding: 3}} onClick={() => close()}>
                               <CloseIcon/>
                           </IconButton>
                       }>
                    {message}
                </Alert>

            </Snackbar>
        );
    }
}

export default EpiAlert;
