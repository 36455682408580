import React from 'react';
import Flex from 'components/grid/Flex';
import {Typography} from '@mui/material';
import * as colors from 'components/colors/Colors';
import BorderLinearProgress from 'scenes/dashboard/components/BorderLinearProgress';
import _ from 'lodash';
import utils from 'utils/Utils';

const Cell = (props) => {
    const { text, bold, transparent, item, color, style, center=true, ...other } = props;
    return (
        <Flex item={item || '1 0 5%'} container center={center} style={style || {width: '5%'}} {...other} >
            <Typography style={{
                color: color || colors.headerBlue,
                opacity: transparent ? 0.6 : 1,
                bold: bold ? 'bold' : 'unset',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap'
            }}>{text}</Typography>
        </Flex>
    );
};

const formatAndRound = (n) => utils.percentFormatter.format(n);

const ProductListRow = (props) => {
    const {product, productType, filterState} = props;
    let rangeProgress = 0;
    if(product?.rwInWo){
        if(product?.rwInWo > 104){
            rangeProgress = 100;
        } else {
            rangeProgress = (product?.rwInWo / 104) * 100;
        }
    }
    let dayProgress = 0;
    if(product?.bstTage){
        if(product?.bstTage > 90){
            dayProgress = 100;
        } else {
            dayProgress = (product?.bstTage / 90) * 100;
        }
    }
    return (
        <Flex item grow={0} container style={{borderBottom: '1px solid #f3f5fd'}}>
            <Cell {...{text: product.artikel}}/>
            {
                filterState?.productionSite === 'ALL' &&
                <Cell {...{text: product.werk}}/>
            }

            <Cell {...{text: product.benennung, center: false,
                alignItems: 'center', item: '1 0 14%', style: {width: '14%'}}}/>
            {
                filterState?.marcDispo === 'ALL' &&
                        <Cell {...{text: product.marcDispo}}/>
            }
            {
                filterState?.marcDismm === 'ALL' &&
                        <Cell {...{text: product.marcDismm}}/>
            }
            {
                filterState?.marcDisls === 'ALL' &&
                        <Cell {...{text: product.marcDisls}}/>
            }
            <Cell {...{text: formatAndRound(product.marcMabst)}}/>
            <Cell {...{text: formatAndRound(product.istbstMge)}} bold/>
            <Cell {...{text: formatAndRound(product.istbstInEur)}}/>
            <Flex item={'1 0 5%'} container column justifyContent={'space-between'} alignItems={'center'}>
                <Typography style={{color: 'grey'}}>{formatAndRound(product.rwInWo)}</Typography>
                <BorderLinearProgress variant="determinate" value={formatAndRound(rangeProgress)}/>
            </Flex>
            <Cell {...{text: formatAndRound(product.bodBstMge)}} transparent/>
            <Cell {...{text: formatAndRound(product.bodBstInEur)}} transparent/>
            <Cell {...{text: formatAndRound(product.verfImKzrInPerc)}}/>

            {
                productType === "WITHOUT_STOCK"
                    ? <Cell {...{text: formatAndRound(product.bstTage), color: 'red'}}/>
                    : <Flex item={'1 0 5%'} container column justifyContent={'space-between'} alignItems={'center'}>
                        <Typography style={{color: 'grey'}}>{formatAndRound(product.bstTage)}</Typography>
                        <BorderLinearProgress variant="determinate" value={formatAndRound(dayProgress)}/>
                    </Flex>
            }

        </Flex>
    );
};

export default ProductListRow;
