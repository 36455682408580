import React, {Component} from 'react';
import Flex from 'components/grid/Flex';
import InputLabel from 'components/input/InputLabel';
import ButtonSwitch from 'components/switch/ButtonSwitch';
import {withTranslation} from 'react-i18next';
import DefaultTextField from 'components/textfield/DefaultTextField';
import {MenuItem, Button} from '@mui/material';
import {connect} from 'react-redux';
import connector from './Filter.connect';
import utils from 'utils/Utils';

export class Filter extends Component {

    onDateChange = event => {
        this.props.setDate(event.target.value);
        if (this.props.refresh) {
            this.props.refresh();
        }
    }

    onFilterChange = event => {
        this.props.setFilterState({
            ...this.props.filterState,
            [event.target.name]: event.target.value
        });
    }

    reset = () => {
        this.props.setFilterState({
            ...this.props.filterState,
            sortiment: 'TLMS',
            productType: 'DAY90',
            productionSite: 'ALL',
            marcDispo: 'ALL',
            marcDismm: 'ALL',
            marcDisls: 'ALL'
        });
    }

    render() {
        const {t: translate, filterState, filterOptions, isCompBasisActive, isProductListActive} = this.props;
        const {date, sortiment, productType, productionSite, marcDispo, marcDismm, marcDisls} = filterState;
        const {dates, sortiments, productTypes, productionSites, marcDispos, marcDismms, marcDislss} = filterOptions;
        const {onDateChange, onFilterChange, reset} = this;
        return (
            <Flex item grow={0} container style={{padding: '0px 15px'}}>
                <DefaultTextField
                    label={translate('global.deadline')}
                    select
                    style={{marginRight: 15, width: 150, marginBottom: 10}}
                    SelectProps={{
                        value: date,
                        onChange: onDateChange,
                        name: 'date'
                    }}
                >
                    {
                        dates && dates.map(item =>
                            <MenuItem key={item} value={item}>
                                {utils.toDateOrDefault(item)}
                            </MenuItem>
                        )
                    }
                </DefaultTextField>
                <DefaultTextField
                    label={translate('global.sortiment')}
                    select
                    style={{marginRight: 15, width: 150, marginBottom: 10}}
                    SelectProps={{
                        value: sortiment,
                        onChange: onFilterChange,
                        name: 'sortiment'
                    }}
                >
                    {
                        sortiments && sortiments.map(item =>
                            <MenuItem key={item} value={item}>
                                {item}
                            </MenuItem>
                        )
                    }
                </DefaultTextField>
                {
                    isCompBasisActive &&
                    <Flex item container column grow={0}>
                        <InputLabel>{translate('global.compare-basis')}</InputLabel>
                        <ButtonSwitch id={'manual-ai-plan'} style={{marginBottom: 10}} {...{
                            disabled: true,
                            isLeftSelected: true,
                            toggle: () => {
                            },
                            labelRight: translate('global.deadline-prices'),
                            labelLeft: translate('global.historical-prices'),
                        }}/>
                    </Flex>
                }
                {
                    isProductListActive &&
                    <Flex item container justifyContent={'space-between'}>
                        <Flex item container grow={4}>
                            <DefaultTextField
                                label={translate('global.production-site')}
                                select
                                style={{marginRight: 15, width: 150, marginBottom: 10}}
                                SelectProps={{
                                    value: productionSite,
                                    onChange: onFilterChange,
                                    name: 'productionSite'
                                }}
                            >
                                {
                                    productionSites && productionSites.map(item =>
                                        <MenuItem key={item} value={item}>
                                            {item}
                                        </MenuItem>
                                    )
                                }
                            </DefaultTextField>
                            <DefaultTextField
                                label={translate('global.marc-dispo')}
                                select
                                style={{marginRight: 15, width: 150, marginBottom: 10}}
                                SelectProps={{
                                    value: marcDispo,
                                    onChange: onFilterChange,
                                    name: 'marcDispo'
                                }}
                            >
                                {
                                    marcDispos && marcDispos.map(item =>
                                        <MenuItem key={item} value={item}>
                                            {item}
                                        </MenuItem>
                                    )
                                }
                            </DefaultTextField>
                            <DefaultTextField
                                label={translate('global.marc-dism')}
                                select
                                style={{marginRight: 15, width: 150, marginBottom: 10}}
                                SelectProps={{
                                    value: marcDismm,
                                    onChange: onFilterChange,
                                    name: 'marcDismm'
                                }}
                            >
                                {
                                    marcDismms && marcDismms.map(item =>
                                        <MenuItem key={item} value={item}>
                                            {item}
                                        </MenuItem>
                                    )
                                }
                            </DefaultTextField>
                            <DefaultTextField
                                label={translate('global.marc-disls')}
                                select
                                style={{marginRight: 15, width: 150, marginBottom: 10}}
                                SelectProps={{
                                    value: marcDisls,
                                    onChange: onFilterChange,
                                    name: 'marcDisls'
                                }}
                            >
                                {
                                    marcDislss && marcDislss.map(item =>
                                        <MenuItem key={item} value={item}>
                                            {item}
                                        </MenuItem>
                                    )
                                }
                            </DefaultTextField>
                        </Flex>
                        <Flex item container>
                            <DefaultTextField
                                label={translate('global.product-type')}
                                select
                                style={{marginRight: 15, width: '100%', marginBottom: 10}}
                                SelectProps={{
                                    value: productType,
                                    onChange: onFilterChange,
                                    name: 'productType'
                                }}
                            >
                                {
                                    productTypes && productTypes.map(item =>
                                        <MenuItem key={item} value={item}>
                                            {translate(`product-type.${item}`)}
                                        </MenuItem>
                                    )
                                }
                            </DefaultTextField>
                            <Button onClick={reset}>Reset</Button>
                        </Flex>
                    </Flex>
                }

            </Flex>

        );
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(
    withTranslation()(
        Filter
    )
);
