import {getPlanDeliveryTime} from 'scenes/dashboard/Dashboard.action';

export default {
    mapStateToProps: (state) => {
        return {
            planDeliveryTimesTab: state.dashboardReducer.planDeliveryTimesTab
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            getPlanDeliveryTime: () => dispatch(getPlanDeliveryTime()),
        };
    }
};