import {
    login,
    requestNewPassword,
    saveNewPassword
} from 'scenes/Main.action';

export default {
    mapStateToProps: state => {
        return {
        }
    },
    mapDispatchToProps: dispatch => {
        return {
            login: (username, password, verifyCode)  => dispatch(login(username, password, verifyCode)),
            requestNewPassword: (username)  => dispatch(requestNewPassword(username)),
            saveNewPassword: (password, token)  => dispatch(saveNewPassword(password, token)),
        }
    }
}
