import React from 'react';
import ArrowDownIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

export default (props) => {
    const {isShown, reverse} = props;
    return (
        <>
            {
                isShown &&
                <>
                    {
                        reverse
                            ?
                            <ArrowDownIcon style={{width: 16, height: 16}}/>
                            :
                            <ArrowUpwardIcon style={{width: 16, height: 16}}/>
                    }
                </>
            }
        </>
    );
}

