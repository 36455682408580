import {logout} from 'scenes/Main.action';

export default {
    mapStateToProps: (state) => {
        return {
            user: state.mainReducer.user,
            isSidebarExpanded: state.mainReducer.isSidebarExpanded
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            logout: () => dispatch(logout()),
        };
    }
};
