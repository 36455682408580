import * as actionTypes from './Main.action.types';
import _ from "lodash";

const initialState = {
    authenticated: false,
    isConfigurationLoaded: false,
    user: '',
    accessToken: undefined,
    isSidebarExpanded: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.FETCH_USER_SUCCESS: {
            const newState = _.cloneDeep(state);
            newState.user = action.payload.data;
            newState.authenticated = true;
            return newState;
        }
        case actionTypes.LOAD_ACCESS_TOKEN: {
            const newState = _.cloneDeep(state);
            newState.accessToken = action.data.accessToken;
            return newState;
        }
        case actionTypes.LOGOUT: {
            const newState = _.cloneDeep(state);
            newState.user = '';
            newState.accessToken = undefined;
            newState.authenticated = false;
            return newState;
        }
        case actionTypes.LOAD_SETUP: {
            const newState = _.cloneDeep(state);
            newState.isConfigurationLoaded = false;
            return newState;
        }
        case actionTypes.LOAD_SETUP_DONE: {
            const newState = _.cloneDeep(state);
            newState.isConfigurationLoaded = true;
            return newState;
        }
        case actionTypes.SET_SIDEBAR_EXPANDED: {
            const newState = _.cloneDeep(state);
            newState.isSidebarExpanded = action.data.isSidebarExpanded;
            return newState;
        }
        default:
            return state;
    }
}
