import {logout, loadAccessToken} from './Main.action'

export default {
    mapStateToProps: state => {
        return {
            user: state.mainReducer.user,
            isConfigurationLoaded: state.mainReducer.isConfigurationLoaded,
            authenticated: state.mainReducer.authenticated,
        }
    },
    mapDispatchToProps: dispatch => {
        return {
            logout: () => dispatch(logout()),
            loadAccessToken: () => dispatch(loadAccessToken()),
        }
    }
}
