export const SET_FILTER_STATE = '@dashboard/SET_FILTER_STATE';
export const SET_FILTER_STATE_SUCCESS = '@dashboard/SET_FILTER_STATE_SUCCESS';
export const SET_FILTER_STATE_FAIL = '@dashboard/SET_FILTER_STATE_FAIL';

export const GET_CONFIGURATION = '@dashboard/GET_CONFIGURATION';
export const GET_CONFIGURATION_SUCCESS = '@dashboard/GET_CONFIGURATION_SUCCESS';
export const GET_CONFIGURATION_FAIL = '@dashboard/GET_CONFIGURATION_FAIL';

export const GET_DATES = '@dashboard/GET_DATES';
export const GET_DATES_SUCCESS = '@dashboard/GET_DATES_SUCCESS';
export const GET_DATES_FAIL = '@dashboard/GET_DATES_FAIL';

export const GET_OVERVIEW = '@dashboard/GET_OVERVIEW';
export const GET_OVERVIEW_SUCCESS = '@dashboard/GET_OVERVIEW_SUCCESS';
export const GET_OVERVIEW_FAIL = '@dashboard/GET_OVERVIEW_FAIL';

export const GET_DETAILS = '@dashboard/GET_DETAILS';
export const GET_DETAILS_SUCCESS = '@dashboard/GET_DETAILS_SUCCESS';
export const GET_DETAILS_FAIL = '@dashboard/GET_DETAILS_FAIL';

export const GET_DISPO = '@dashboard/GET_DISPO';
export const GET_DISPO_SUCCESS = '@dashboard/GET_DISPO_SUCCESS';
export const GET_DISPO_FAIL = '@dashboard/GET_DISPO_FAIL';

export const GET_PLAN_DELIVERY_TIMES = '@dashboard/GET_PLAN_DELIVERY_TIMES';
export const GET_PLAN_DELIVERY_TIMES_SUCCESS = '@dashboard/GET_PLAN_DELIVERY_TIMES_SUCCESS';
export const GET_PLAN_DELIVERY_TIMES_FAIL = '@dashboard/GET_PLAN_DELIVERY_TIMES_FAIL';

export const GET_GRAPHS = '@dashboard/GET_GRAPHS';
export const GET_GRAPHS_SUCCESS = '@dashboard/GET_GRAPHS_SUCCESS';
export const GET_GRAPHS_FAIL = '@dashboard/GET_GRAPHS_FAIL';

export const GET_PRODUCT_LIST = '@dashboard/GET_PRODUCT_LIST';
export const GET_PRODUCT_LIST_SUCCESS = '@dashboard/GET_PRODUCT_LIST_SUCCESS';
export const GET_PRODUCT_LIST_FAIL = '@dashboard/GET_PRODUCT_LIST_FAIL';

export const SET_DATE = '@dashboard/SET_DATE';
export const SET_SORTIMENT = '@dashboard/SET_SORTIMENT';
export const SET_SORT_STATE = '@dashboard/SET_SORT_STATE';
