import {useParams, useNavigate, useLocation} from 'react-router-dom';

export function withNavigation(Component) {
    return props => <Component {...props} navigate={useNavigate()} />;
}

export function withRouteParams(Component) {
    return props => <Component {...props} routeParams={useParams()} />;
}

export function withLocation(Component) {
    return props => <Component {...props} location={useLocation()} />;
}

export function withRouter(Component) {
    return props => <Component {...props}
                               location={useLocation()}
                               navigate={useNavigate()}
                               routeParams={useParams()}
    />;
}
