import React, {Component} from 'react';
import Flex from 'components/grid/Flex';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import connector from './ProductList.connect';
import ProductListHeader from './ProductListHeader';
import ProductListRow from './ProductListRow';
import Filter from 'scenes/dashboard/components/Filter';
import {FixedSizeList} from 'react-window';
import {useResizeDetector} from 'react-resize-detector';

function ProductListWindow(props) {
    const {width, height, ref} = useResizeDetector();
    const {productList, productType, filterState} = props;
    return <div ref={ref} style={{height: '98%'}}>
        <FixedSizeList
            height={height || 300}
            itemCount={productList.length}
            itemSize={37}
            width={width || 500}
            itemData={{rows: productList}}
        >
            {({index, style}) => (
                <div key={index} style={style}>
                    <ProductListRow product={productList[index]} productType={productType} filterState={filterState}/>
                </div>
            )}
        </FixedSizeList>
    </div>;
}

export class ProductList extends Component {

    componentDidMount() {
        this.props.getProductList();
    }

    onSort = sortParameter => event => {
        this.props.setSortState({
            sortParameter,
            reverseSort: this.props.sortParameter === sortParameter ? !this.props.reverseSort : false
        });
    };

    render() {
        const {t: translate, productList, productType, sortParameter, reverseSort, filterState} = this.props;
        const {onSort} = this;
        return (
            <Flex item container column padding={5} style={{height: '100%', width: '97%', marginLeft: '1%'}}>
                <Filter refresh={this.props.getProductList} isProductListActive/>
                <ProductListHeader {...{
                    translate, productType, onSort, sortParameter, reverseSort, filterState
                }}/>
                <ProductListWindow productList={productList} productType={productType} filterState={filterState}/>
            </Flex>
        );
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(
    withTranslation()(
        ProductList
    )
);
