import React from 'react';
import Flex from 'components/grid/Flex';
import {Typography} from '@mui/material';
import SortIcon from 'components/sort-icon/SortIcon';

const HeaderItem = (props) => {
    const {onSort, sortParameter, sortName, title, reverseSort, item, style} = props;
    return (
        <Flex item={item || '1 0 5%'} container onClick={onSort(sortName)} center style={style || {width: '5%'}}>
            <Typography style={{color: '#585757', fontSize: 13}}>{title}</Typography>
            <SortIcon isShown={sortParameter === sortName} reverse={reverseSort}/>
        </Flex>
    );
};

export default (props) => {
    const {translate, onSort, sortParameter, reverseSort, productType, filterState} = props;
    return (
        <Flex item container grow={0} style={{backgroundColor: "#f3f5fd"}}>
            <HeaderItem {...{
                title: translate('global.item'),
                sortName: 'artikel',
                onSort, reverseSort, sortParameter,
            }}/>
            {
                filterState?.productionSite === 'ALL' &&
                <HeaderItem {...{
                    title: translate('global.production-site'),
                    sortName: 'werk',
                    onSort, reverseSort, sortParameter,
                }}/>
            }
            <HeaderItem {...{
                title: translate('global.designation'),
                sortName: 'benennung',
                item: '1 0 14%',
                style: {width: "14%"},
                onSort, reverseSort, sortParameter,
            }}/>

            {
                filterState?.marcDispo === 'ALL' &&
                <HeaderItem {...{
                    title: translate('global.marc-dispo'),
                    sortName: 'marcDispo',
                    onSort, reverseSort, sortParameter,
                }}/>
            }
            {
                filterState?.marcDismm === 'ALL' &&
                <HeaderItem {...{
                    title: translate('global.marc-dismm'),
                    sortName: 'marcDismm',
                    onSort, reverseSort, sortParameter,
                }}/>
            }
            {
                filterState?.marcDisls === 'ALL' &&
                <HeaderItem {...{
                    title: translate('global.marc-disls'),
                    sortName: 'marcDisls',
                    onSort, reverseSort, sortParameter,
                }}/>
            }
            <HeaderItem {...{
                title: translate('global.marc-mabst'),
                sortName: 'marcMabst',
                onSort, reverseSort, sortParameter,
            }}/>
            <HeaderItem {...{
                title: translate('global.stock-in-MEH'),
                sortName: 'istbstMge',
                onSort, reverseSort, sortParameter,
            }}/>
            <HeaderItem {...{
                title: translate('global.stock-in-EUR'),
                sortName: 'istbstInEur',
                onSort, reverseSort, sortParameter,
            }}/>
            <HeaderItem {...{
                title: translate('global.reach-in-week'),
                sortName: 'rwInWo',
                onSort, reverseSort, sortParameter,
            }}/>
            <HeaderItem {...{
                title: translate('global.bod-best-in-MEH'),
                sortName: 'bodBstMge',
                onSort, reverseSort, sortParameter,
            }}/>
            <HeaderItem {...{
                title: translate('global.bod-best-in-EUR'),
                sortName: 'bodBstInEur',
                onSort, reverseSort, sortParameter,
            }}/>
            <HeaderItem {...{
                title: translate('global.available-in-KZR-in-%'),
                sortName: 'verfImKzrInPerc',
                onSort, reverseSort, sortParameter,
            }}/>
            <HeaderItem {...{
                title: translate('global.bestands-tage'),
                sortName: 'bstTage',
                onSort, reverseSort, sortParameter,
            }}/>

        </Flex>
    );
};
