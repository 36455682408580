import React, { Component } from 'react';
import { TextField } from "@mui/material";
import { styled } from '@mui/material/styles';
import _ from 'lodash';

const FormInput = styled((props) => <TextField {...props} />)(({ theme }) => ({
    "& input": {
        minHeight: '40px',
        padding: "0px 20px",
        borderRadius: "10px",
    },
    "& fieldset": {
        border: "1px solid #d1d1d1",
        borderRadius: "10px",
    }
}));

/**
 * TODO LM: REMOVE this component and replace with DefaultTextfield from hmo
 */
export class CustomTextField extends Component {
    selectAllOnFocus = event => {
        event.target.select && event.target.select();
    };
    handleKeyPress = (onEnterKey, onKeyPress) => event => {
        const isEnterPressed = (event.key && event.key === 'Enter') || (event.keyCode && event.keyCode === 13);
        const isShiftPressed = event.shiftKey;
        if (isEnterPressed && !isShiftPressed) {
            onEnterKey && onEnterKey(event);
        } else {
            onKeyPress && onKeyPress(event);
        }
    };

    render() {
        const {
            classes, onEnterKey, onKeyPress, onFocus, variant, inputPropsStyle, inputProps,
            autoComplete = "new-password", warning, info, InputProps, multiline, ...otherProps
        } = this.props;

        return (
            <FormInput
                onKeyPress={this.handleKeyPress(onEnterKey, onKeyPress)}
                onFocus={onFocus ? onFocus : this.selectAllOnFocus}
                multiline={multiline}
                {...{
                    ...otherProps,
                    variant,
                    inputProps: {
                        ...inputProps,
                        autoComplete
                    },
                    ...(variant === 'outlined' ? {
                        inputProps: {
                            ...inputProps,
                            autoComplete,
                            style: {
                                padding: '8px 10px',
                                backgroundColor: 'white',
                                ...inputPropsStyle
                            }
                        }
                    } : {
                        inputProps: {
                            ...inputProps,
                            autoComplete,
                            style: {
                                padding: '0px 8px',
                                backgroundColor: 'white',
                                ...inputPropsStyle
                            }
                        }
                    }),
                    InputProps: {
                        ...InputProps,
                        style: { ...(multiline && { padding: 0 }) },
                        classes: {
                            ..._.get(InputProps, 'classes'),
                            ...(warning && { notchedOutline: classes.notchedOrangeOutline }),
                            ...(info && { notchedOutline: classes.notchedBlueOutline })
                        },
                    }
                }}
            />
        );
    }
}
export default (CustomTextField);
