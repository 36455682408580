import React, {Component, Fragment} from 'react';
import {Typography} from "@mui/material";
import Flex from "components/grid/Flex";
import configs from "configs/Configs";
import {withTranslation} from "react-i18next";
import _ from 'lodash';
import {transparentBlack054, htmlBlack} from 'components/colors/Colors';

class LanguageSelector extends Component {
    constructor(props) {
        super(props);

        const {i18n} = this.props;
        this.state = {
            selectedLanguage: i18n.language,
            allowedLanguages: configs.getAllowedLanguages(),
        };
    }

    changeLanguage = language => {
        const {i18n} = this.props;

        i18n.changeLanguage(language).then(value => {
            this.setState({
                selectedLanguage: language
            });
        });
    };

    render() {
        const isMobile = _.get(this.props, 'data.isMobile');
        return (
            <Flex item container grow={0} alignItems={isMobile ? 'center' : undefined}>
                {
                    this.state.allowedLanguages.map((value, index) => (
                            <Fragment key={index}>
                                <Flex item grow={0}>
                                    <Typography variant="button"
                                                color="inherit"
                                                onClick={() => this.changeLanguage(value)}
                                                style={{
                                                    color: isMobile ? transparentBlack054 : htmlBlack,
                                                    padding: 3,
                                                    fontWeight: value === this.state.selectedLanguage && 'bold',
                                                    cursor: 'pointer'
                                                }}
                                    >
                                        {value}
                                    </Typography>

                                </Flex>
                                {index !== this.state.allowedLanguages.length - 1 && !isMobile &&
                                <Flex item grow={0}>
                                    <Typography variant="button" color="inherit" style={{padding: 3}}>|</Typography>
                                </Flex>}
                            </Fragment>
                        )
                    )
                }
            </Flex>
        );
    }
}

export default withTranslation()(LanguageSelector);
