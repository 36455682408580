import React, {Component} from 'react';
import {Tabs, Tab, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import Flex from 'components/grid/Flex';
import Sidebar, {SIDEBAR_EXPANDED_WIDTH, SIDEBAR_SHRANK_WIDTH} from 'components/sidebar/Sidebar';
import {connect} from 'react-redux';
import connector from './Dashboard.connect';
import {withTranslation, Trans} from 'react-i18next';
import {withRouter} from 'utils/Route.utils';
import Overview from 'scenes/dashboard/overview/Overview';
import Details from 'scenes/dashboard/details/Details';
import Dispo from 'scenes/dashboard/dispo/Dispo';
import PlannedDelivery from 'scenes/dashboard/planned-delivery/PlannedDelivery';
import Graphics from 'scenes/dashboard/graphics/Graphics';
import ProductList from 'scenes/dashboard/product-list/ProductList';
import {alabasterWhite, slateGrey, htmlBlack, headerBlue} from 'components/colors/Colors';
import EpiErrorAlert from 'components/alert/EpiErrorAlert';

export const OVERVIEW = 'OVERVIEW';
export const DETAILS = 'DETAILS';
export const PLANNED_DELIVERY = 'PLANNED_DELIVERY';
export const DISPO = 'DISPO';
export const GRAPHICS = 'GRAPHICS';
export const PRODUCT_LIST = 'PRODUCT_LIST';

const CustomTab = styled((props) => <Tab {...props} />)(
    ({theme}) => ({
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightBold,
        fontSize: theme.typography.pxToRem(14),
        color: slateGrey,
        '&.Mui-selected': {
            color: headerBlue,
        },
    }),
);

export class Dashboard extends Component {
    state = {
        tab: OVERVIEW,
    };

    componentDidMount() {
        this.props.getConfiguration();
    }

    handleTabChange = (event, value) => {
        this.changeTab(value);
    };

    changeTab = tab => {
        this.setState({tab});
    }

    render() {
        const {t: translate, isSidebarExpanded, selectedDate} = this.props;
        const {tab} = this.state;
        const {handleTabChange, changeTab} = this;
        return (
            <Flex item container column>
                <Sidebar/>
                <Flex item container
                      style={{
                          marginLeft: isSidebarExpanded ? SIDEBAR_EXPANDED_WIDTH : SIDEBAR_SHRANK_WIDTH,
                          height: '100vh'
                      }}
                      column>
                    <Flex item={'0 1 auto'} container id={'TABS'} style={{
                        padding: 12, paddingBottom: 0, backgroundColor: alabasterWhite
                    }}>
                        <Flex item container grow={0}>
                            <Typography style={{fontSize: 28, fontWeight: 'bold', marginRight: 20, color: headerBlue}}>
                                <Trans i18nKey={'global.dashboard'}/>
                            </Typography>
                        </Flex>
                        <Tabs value={tab} onChange={handleTabChange} variant="scrollable"
                              scrollButtons="auto">
                            <CustomTab value={OVERVIEW} label={translate('global.OVERVIEW')}/>
                            <CustomTab value={DETAILS} label={translate('global.DETAILS')}/>
                            <CustomTab value={PLANNED_DELIVERY} label={translate('global.PLANNED_DELIVERY')}/>
                            <CustomTab value={DISPO} label={translate('global.DISPO')}/>
                            <CustomTab value={GRAPHICS} label={translate('global.GRAPHICS')}/>
                            <CustomTab value={PRODUCT_LIST} label={translate('global.product-list')}/>
                        </Tabs>
                    </Flex>
                    {
                        tab === OVERVIEW && selectedDate &&
                        <Overview/>
                    }
                    {
                        tab === DETAILS && selectedDate &&
                        <Details changeTab={changeTab}/>
                    }
                    {
                        tab === PLANNED_DELIVERY && selectedDate &&
                        <PlannedDelivery/>
                    }
                    {
                        tab === DISPO && selectedDate &&
                        <Dispo/>
                    }
                    {
                        tab === GRAPHICS && selectedDate &&
                        <Graphics/>
                    }
                    {
                        tab === PRODUCT_LIST && selectedDate &&
                        <ProductList/>
                    }

                </Flex>
                <EpiErrorAlert/>
            </Flex>
        );
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(
    withTranslation()(
        withRouter(Dashboard)
    )
);

