import {setFilterState, setDate} from 'scenes/dashboard/Dashboard.action';

export default {
    mapStateToProps: (state) => {
        return {
            user: state.mainReducer.user,
            filterOptions: state.dashboardReducer.filterOptions,
            filterState: state.dashboardReducer.filterState,
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            setDate: date => dispatch(setDate(date)),
            setFilterState: filterState => dispatch(setFilterState(filterState)),
        };
    }
};
