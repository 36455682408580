import {
    closeAlert
} from './EpiErrorAlert.action';

export default {
    mapStateToProps: (state) => {
        return {
            open: state.alertReducer.open
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            closeAlert: () => dispatch(closeAlert()),
        };
    }
};
