import {getOverview} from 'scenes/dashboard/Dashboard.action';

export default {
    mapStateToProps: (state) => {
        return {
            overviewTab: state.dashboardReducer.overviewTab
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            getOverview: () => dispatch(getOverview()),
        };
    }
};
