import React from 'react';
import Flex from 'components/grid/Flex';
import {Typography} from '@mui/material';
import {styled} from '@mui/material/styles';

const HeaderItem = styled((props) => <Flex item={'1 0 14%'} container center>
    <Typography {...props}/>
</Flex>)(({theme}) => ({
    color: '#585757',
    fontSize: 13
}));

export default (props) => {
    const {translate} = props;
    return (
        <Flex item container grow={0} style={{backgroundColor: "#f3f5fd", padding: '14px 4px'}}>
            <HeaderItem/>
            <HeaderItem>
                {translate('global.number-of-product')}
            </HeaderItem>
            <HeaderItem>
                {translate('global.stock-in-quantity')}
            </HeaderItem>
            <HeaderItem>
                {translate('global.stock')}
            </HeaderItem>
            <HeaderItem>
                {translate('global.reach-in-week')}
            </HeaderItem>
            <HeaderItem>
                {translate('global.departure-in-eur')}
            </HeaderItem>
            <HeaderItem>
                {translate('global.plan-bestand')}
            </HeaderItem>
        </Flex>
    );
};
