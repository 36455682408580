import React, {Component} from 'react';
import Flex from 'components/grid/Flex';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import connector from './Overview.connect';
import Number from 'components/number/Number';
import InputLabel from 'components/input/InputLabel';
import OutboundOutlinedIcon from '@mui/icons-material/OutboundOutlined';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import EurValueAreaChart from './EurValueAreaChart';
import Filter from 'scenes/dashboard/components/Filter';
import * as colors from 'components/colors/Colors';
import utils from 'utils/Utils';

export function trendEurColor(number){
    if(number === 0 || number === "0") return 'grey';
    return number > 3
        ? 'red'
        : number < 3 && number > 0
            ? 'orange'
            : colors.persianGreen
}

export function trendArticleColor(number){
    if(number === 0 || number === "0") return 'grey';
    return number < -3
        ? 'red'
        : number > -3 && number < 0
            ? 'orange'
            : colors.persianGreen
}

const Arrow = (props) => {
    let {type, trendColor} = props;
    trendColor = trendColor ?? trendArticleColor;
    return (
        <>
            {
                (type === 'UP' || type > 0) &&
                <OutboundOutlinedIcon style={{fontSize: 300, fill: trendColor(type)}}/>
            }
            {
                (type === 'DOWN' || type < 0) &&
                <OutboundOutlinedIcon style={{fontSize: 300, transform: 'rotate(90deg)', fill: trendColor(type)}}/>
            }
            {
                (!type || type === 'NONE' || type === 0) &&
                <div style={{border: '25px solid lightgrey', borderRadius: '50%', padding: 30, margin: 20}}>
                    <OpenInFullIcon style={{fontSize: 145, transform: 'rotate(45deg)', fill: 'lightgrey'}}/>
                </div>
            }

        </>
    );
};

export class Overview extends Component {

    componentDidMount() {
        this.props.getOverview();
    }

    render() {
        const {t: translate, overviewTab} = this.props;
        const numberOfArticle = overviewTab?.numberOfArticle || {};
        const stockInEUR = overviewTab?.stockInEUR || {};
        return (
            <Flex item container column padding={5} style={{height: '100%'}}>
                <Filter refresh={this.props.getOverview}/>
                <Flex item container>
                    <Flex item container column center>
                        <InputLabel>{translate('global.number-of-items')}</InputLabel>
                        <Flex item container grow={0}>
                            <Flex container justifyContent={'flex-end'} padding={5}>
                                <Number value={numberOfArticle.value} style={{fontSize: 24}}/>
                            </Flex>
                            <Flex container justifyContent={'flex-end'} column style={{padding: '10px 0px'}}>
                                <Number value={numberOfArticle.lastTwoTrend} percent
                                        style={{
                                            fontSize: 12,
                                            color: trendArticleColor(numberOfArticle.lastTwoTrend)
                                        }}/>
                            </Flex>
                        </Flex>
                        <Arrow type={numberOfArticle.lastTwoTrend}/>
                        <InputLabel style={{marginBottom: 5}}>{translate('global.development')}</InputLabel>
                        <div style={{width: 200, height: 120}}>
                            <EurValueAreaChart {...{
                                data: numberOfArticle.history,
                                min: numberOfArticle.min,
                                max: numberOfArticle.max,
                                formatter: (value, name, props) => value
                            }}/>
                        </div>

                        <InputLabel style={{marginTop: 20}}>{translate('global.since-peak')}</InputLabel>
                        <Number value={numberOfArticle.highestTrend} percent
                                style={{
                                    fontSize: 18,
                                    color: trendArticleColor(numberOfArticle.highestTrend)
                                }}/>
                    </Flex>
                    <Flex item container column center>
                        <InputLabel>{translate('global.stock')}</InputLabel>
                        <Flex item container grow={0}>
                            <Flex container justifyContent={'flex-end'} padding={5}>
                                <Number currency value={stockInEUR.value} style={{fontSize: 24}}/>
                            </Flex>
                            <Flex container justifyContent={'flex-end'} column style={{padding: '10px 0px'}}>
                                <Number value={stockInEUR.lastTwoTrend} percent
                                        style={{
                                            fontSize: 12,
                                            color: trendEurColor(stockInEUR.lastTwoTrend)
                                        }}/>
                            </Flex>
                        </Flex>
                        <Arrow type={stockInEUR.lastTwoTrend} trendColor={trendEurColor}/>
                        <InputLabel style={{marginBottom: 5}}>{translate('global.development')}</InputLabel>
                        <div style={{width: 200, height: 120}}>
                            <EurValueAreaChart {...{
                                data: stockInEUR.history,
                                min: stockInEUR.min,
                                max: stockInEUR.max,
                                formatter: (value, name, props) => [utils.currencyFormatter.format(value), "Value"]
                            }}/>
                        </div>
                        <InputLabel style={{marginTop: 20}}>{translate('global.since-low')}</InputLabel>
                        <Number value={stockInEUR.lowestTrend} percent style={{
                            fontSize: 18, color: trendEurColor(stockInEUR.lowestTrend)
                        }}/>
                    </Flex>
                </Flex>
            </Flex>
        );
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(
    withTranslation()(
        Overview
    )
);
