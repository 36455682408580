import React from 'react';
import Flex from 'components/grid/Flex';
import {lastTwoTrend} from 'scenes/dashboard/Dashboard.reducer';
import Number from 'components/number/Number';
import * as colors from 'components/colors/Colors';
import GraphedNumberCell from 'scenes/dashboard/components/GraphedNumberCell';
import BorderLinearProgress from 'scenes/dashboard/components/BorderLinearProgress';
import {Typography} from '@mui/material';

const DefaultGraphedRow = ({data, subTitle, title}) => {
    const articleTrend = lastTwoTrend(data?.historicalNumberOfArticles);
    const trendStockInEuro = lastTwoTrend(data?.historicalStockInEuro);
    let rangeProgress = 0;
    if(data?.rangeInWeeks){
        if(data?.rangeInWeeks > 104){
            rangeProgress = 100;
        } else {
            rangeProgress = (data?.rangeInWeeks / 104) * 100;
        }
    }
    return (
        <Flex item container grow={0} padding={5} style={{borderBottom: '2px solid grey'}}>
            <Flex item={'1 0 14%'} container center style={{backgroundColor: "#f3f5fd"}} column>
                <Typography style={{color: colors.headerBlue, fontSize: 15, fontWeight: 'bold'}}>
                    {title}
                </Typography>
                <Typography style={{color: colors.headerBlue, fontSize: 12}}>
                    {subTitle}
                </Typography>
            </Flex>
            <GraphedNumberCell {...{
                mainNumber: data?.numberOfActiveArticles,
                bottomStyle: {fontSize: 12, color: 'grey'},
                bottomNumber: articleTrend,
                graphData: data?.historicalNumberOfArticles
            }}/>
            <GraphedNumberCell {...{
                mainNumber: data?.stockInPieces,
                mainStyle: {color: 'grey'},
                bottomStyle: {fontSize: 12, color: 'grey'},
                bottomNumber: lastTwoTrend(data?.historicalStockInPieces),
                graphData: data?.historicalStockInPieces
            }}/>
            <GraphedNumberCell {...{
                mainNumber: data?.stockInEuro,
                currency: true,
                bottomStyle: {fontSize: 12, color: 'grey'},
                bottomNumber: trendStockInEuro,
                graphData: data?.historicalStockInEuro
            }}/>
            <Flex item={'1 0 14%'} container column>
                <Flex item container center>
                    <Number value={data?.rangeInWeeks}/>
                </Flex>
                <Flex item container style={{maxHeight: 46, height: 46}} alignItems={'center'}>
                    <BorderLinearProgress variant="determinate" value={rangeProgress} style={{width: '100%'}}/>
                </Flex>
            </Flex>
            <GraphedNumberCell {...{
                mainNumber: data?.outflowingEuro,
                currency: true,
                mainStyle: {color: 'grey'},
                bottomStyle: {fontSize: 12, color: 'grey'},
                bottomNumber: lastTwoTrend(data?.historicalOutflowingEuro),
                graphData: data?.historicalOutflowingEuro
            }}/>
            <Flex item={'1 0 14%'} container center column>
                <Number value={data?.planStockEndOfYearInEuro} currency/>
                <div style={{width: '100%', height: 50}}/>
            </Flex>
        </Flex>
    );
};

export default DefaultGraphedRow;
