import React, {Component} from 'react';
import { AreaChart, Area, ResponsiveContainer, YAxis, Tooltip, XAxis } from 'recharts';

export class EurValueAreaChart extends Component {
    render() {
        const {data, min, max, formatter} = this.props;
        const difference = max-min;
        return (
            <ResponsiveContainer width="100%" height="100%">
                <AreaChart width={730} height={250} data={data}>
                    <Tooltip formatter={formatter}/>
                    <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#f3f5fd" stopOpacity={1}/>
                            <stop offset="95%" stopColor="#f3f5fd" stopOpacity={0.3}/>
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="date" hide />
                    <YAxis type="number" domain={[difference ? min - difference : 'dataMin', 'dataMax']} hide/>
                    <Area type="monotone" dataKey="value" stroke="#82ca9d" fillOpacity={1} fill="url(#colorUv)"/>
                </AreaChart>
            </ResponsiveContainer>
        );
    }
}

export default EurValueAreaChart;
