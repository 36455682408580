import React, { Component } from 'react';
import { FormControl, Card, CardContent, CardMedia, IconButton, InputAdornment, Typography, CircularProgress } from "@mui/material";
import { styled } from '@mui/material/styles';
import CustomFormLabel from 'components/input-label/CustomFormLabel';
import CustomTextField from 'components/custom-textfield/CustomTextField';
import CustomFilledButton from 'components/button/CustomFilledButton';
import { htmlGreen, htmlRed, transparentBlack054 } from 'components/colors/Colors';
import Flex from 'components/grid/Flex';
import { withTranslation } from 'react-i18next';
import { VisibilityOff, Visibility } from "@mui/icons-material";
import EpiLogo from 'assets/img/paa-logo.svg';

const FormCard = styled((props) => <Card {...props} />)(({ theme }) => ({
    borderRadius: "16px",
    boxShadow: 'none',
    padding: '22px'
}));
const FormTitle = styled((props) => <Typography {...props} />)(({ theme }) => ({
    fontFamily: 'Inter',
    fontSize: '28px',
    fontWeight: '600',
    lineHeight: '38px',
    marginBottom: '20px'
}));

export class ResetPassword extends Component {
    passwordInputRef = React.createRef();
    state = {
        newPassword: '',
        newPasswordRepeat: '',
        isMatchingPasswords: true,
        isLongEnough: true,
        isNewPasswordBeingSaved: false,
        isNewPasswordSaved: false,
        errorDuringSave: false,
        isPasswordVisibleNew: false,
        isPasswordVisibleRepeat: false,
    };
    handleStateChange = event => {
        const target = event.target;
        this.setState({
            [target.name]: target.value,
        }, () => {
            this.setState({
                isMatchingPasswords: this.state.newPassword === this.state.newPasswordRepeat,
                isLongEnough: this.state.newPassword.length > 7
            });
        })
    };
    handleSubmit = () => {
        if (this.state.isLongEnough && this.state.isMatchingPasswords) {
            this.setState({
                isNewPasswordBeingSaved: true,
                isNewPasswordSaved: false
            }, () => this.props.saveNewPassword(this.state.newPassword, this.props.token)
                .then(() => {
                    this.setState({
                        isNewPasswordBeingSaved: false,
                        isNewPasswordSaved: true
                    });
                })
                .catch(() => {
                    this.setState({
                        isNewPasswordBeingSaved: false,
                        isNewPasswordSaved: true,
                        errorDuringSave: true
                    });
                }));
        }
    };
    togglePasswordVisibilityNew = () => {
        this.setState(state => ({ isPasswordVisibleNew: !state.isPasswordVisibleNew }));
    };
    togglePasswordVisibilityRepeat = () => {
        this.setState(state => ({ isPasswordVisibleRepeat: !state.isPasswordVisibleRepeat }));
    };
    onEnter = event => {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.passwordInputRef.current.focus();
        }
    };
    goLogin = () => {
        const { history } = this.props;
        history.push("/");
    }
    render() {
        const { classes, t: translate, changeView } = this.props;
        const { handleStateChange, handleSubmit, togglePasswordVisibilityNew, togglePasswordVisibilityRepeat, passwordInputRef, onEnter, goLogin } = this;
        const {
            newPassword, newPasswordRepeat, isMatchingPasswords, isLongEnough, isNewPasswordBeingSaved,
            isNewPasswordSaved, errorDuringSave, isPasswordVisibleNew, isPasswordVisibleRepeat
        } = this.state;
        return (
            <div style={{ width: "100%", height: "100%", display: 'flex' }}>
                <div style={{ width: '100%', maxWidth: '520px', margin: "auto" }}>
                    <FormCard variant="outlined">
                        <CardContent>
                            <form>
                                <CardMedia component="img" image={EpiLogo} alt="logo" style={{ width: 180, margin: "0 auto 30px" }} />
                                <FormTitle component="h3" textAlign="center">{translate('login.reset-password-new')}</FormTitle>
                                <Typography style={{ color: transparentBlack054, marginBottom: 30, fontSize: 14 }} dangerouslySetInnerHTML={{ __html: translate('login.reset-password-new-text').replaceAll("'", "") }}>
                                </Typography>
                                {
                                    !isNewPasswordSaved &&
                                    <>
                                        <FormControl margin="normal" required fullWidth>
                                            <CustomFormLabel>{translate('login.reset-password-new-request')}</CustomFormLabel>
                                            <CustomTextField
                                                size={'small'}
                                                name={'newPassword'}
                                                helperText={isLongEnough ? '' : translate("login.reset-password-new-too-short")}
                                                error={!isLongEnough}
                                                type={isPasswordVisibleNew ? 'text' : 'password'}
                                                value={newPassword}
                                                onChange={handleStateChange}
                                                onEnterKey={onEnter}
                                                placeholder={translate("login.reset-password-new-hint")}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="Toggle password visibility"
                                                                onClick={togglePasswordVisibilityNew}
                                                            >
                                                                {isPasswordVisibleNew ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>)
                                                }}
                                            />
                                        </FormControl>
                                        <FormControl margin="normal" required fullWidth>
                                            <CustomFormLabel>{translate('login.reset-password-new-repeat')}</CustomFormLabel>
                                            <CustomTextField
                                                size={'small'}
                                                name={'newPasswordRepeat'}
                                                helperText={!newPasswordRepeat || isMatchingPasswords ? '' : translate("login.reset-password-new-not-same")}
                                                error={!!newPasswordRepeat && !isMatchingPasswords}
                                                inputRef={passwordInputRef}
                                                type={isPasswordVisibleRepeat ? 'text' : 'password'}
                                                value={newPasswordRepeat}
                                                onChange={handleStateChange}
                                                onEnterKey={handleSubmit}
                                                placeholder={translate("login.reset-password-new-repeat-hint")}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="Toggle password visibility"
                                                                onClick={togglePasswordVisibilityRepeat}
                                                            >
                                                                {isPasswordVisibleRepeat ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>)
                                                }}
                                            />
                                        </FormControl>
                                    </>
                                }
                                {
                                    isNewPasswordSaved &&
                                    <Typography style={{ color: errorDuringSave ? htmlRed : htmlGreen, marginBottom: 30, marginTop: 30 }}>
                                        {
                                            errorDuringSave
                                                ? translate('login.reset-password-new-save-fail')
                                                : translate('login.reset-password-new-save-success')
                                        }
                                    </Typography>
                                }
                                <Flex item container style={{ marginTop: 20 }}>
                                    {
                                        isNewPasswordSaved &&
                                        <CustomFilledButton
                                            fullWidth
                                            variant="contained"
                                            onClick={() => changeView('LOGIN')}
                                        >
                                            {translate('login.reset-password-new-done')}
                                        </CustomFilledButton>
                                    }
                                    {
                                        !isNewPasswordSaved &&
                                        <>
                                            {
                                                changeView &&
                                                <CustomFilledButton
                                                    fullWidth
                                                    style={{
                                                        marginRight: 10
                                                    }}
                                                    variant="contained"
                                                    onClick={() => changeView('LOGIN')}
                                                >
                                                    {translate('global.cancel')}
                                                </CustomFilledButton>
                                            }
                                            <CustomFilledButton
                                                fullWidth
                                                variant="contained"
                                                onClick={handleSubmit}
                                            >
                                                {translate('login.reset-password-new-save')}
                                            </CustomFilledButton>
                                        </>
                                    }
                                </Flex>
                                {
                                    isNewPasswordBeingSaved &&
                                    <Flex item container style={{ marginTop: 20 }} justifyContent={'center'} alignItems={'center'}>
                                        <CircularProgress />
                                    </Flex>
                                }
                            </form>
                        </CardContent>
                    </FormCard>
                </div>
            </div>
        );
    }
}
export default withTranslation()(ResetPassword);