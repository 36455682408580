import Decimal from 'decimal.js-light';
export function divide(base, divisor) {
    return _divide(base, divisor).toDecimalPlaces(12).toString();
}

export function _divide(base, divisor) {
    return new Decimal(base).dividedBy(new Decimal(divisor));
}
export function multiply(base, multiplier) {
    return _multiply(base, multiplier).toDecimalPlaces(12).toString();
}

export function _multiply(base, multiplier) {
    return new Decimal(base).mul(new Decimal(multiplier));
}

export function _add(a, b) {
    return new Decimal(a).add(new Decimal(b));
}

export function _subtract(a, b) {
    return new Decimal(a).sub(new Decimal(b));
}
