import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  Fragment,
} from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { logout } from "scenes/Main.action";

const SessionTimeout = () => {
  const [events] = useState(["click", "load", "scroll"]);

  const isAuthenticated = useSelector((state) => {
    return state.mainReducer.authenticated;
  });

  let startTimerInterval = useRef();

  // start inactive check
  let timeChecker = () => {
    startTimerInterval.current = setTimeout(() => {
      let minLogout = 120;

      let storedTimeStamp = window.localStorage.getItem("lastTimeStamp");

      const minPast = moment.duration(moment().diff(moment(storedTimeStamp))).asMinutes();


      if(isNaN(minPast)) {
        let timeStamp = moment().toISOString();
        window.localStorage.setItem("lastTimeStamp", timeStamp);
        timeChecker();
        return;
      }

       if(minLogout <= minPast) {
        logout();
      } else if(isAuthenticated) {
        timeChecker();
      }
    }, 60000);
  };

  // reset interval timer
  let resetTimer = useCallback(() => {
    clearTimeout(startTimerInterval.current);
    if (isAuthenticated) {
      let timeStamp = moment().toISOString();
      window.localStorage.setItem("lastTimeStamp", timeStamp);
      timeChecker();
    }

  }, [isAuthenticated]);

  useEffect(() => {
    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    timeChecker();

    return () => {
      clearTimeout(startTimerInterval.current);
    };
  }, [resetTimer, events, timeChecker]);

  return <Fragment />;
};

export default SessionTimeout;
