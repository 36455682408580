import React, { Component } from 'react';
import { styled } from '@mui/material/styles';
import { FormLabel } from "@mui/material";

const FormLbl = styled((props) => <FormLabel {...props} />)(({ theme }) => ({
    fontWeight: '500',
    fontSize: '13px',
    color: "#425466",
    marginBottom: "10px"
}));

export class CustomFormLabel extends Component {
    render() {
        const { children } = this.props;
        return <FormLbl>{children}</FormLbl>;
    }
}
export default (CustomFormLabel);