import React, { Component, Fragment } from 'react';
import {Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import {headerBlue} from 'components/colors/Colors';
import utils from 'utils/Utils';
const CustomNumber = styled((props) => <Typography {...props} />)(({ theme }) => ({
    color: headerBlue,
    fontWeight: 'bold',
    fontSize: 15
}));

export class Number extends Component {
    render() {
        const {currency, percent, value=0, prefix, postfix, onClick, ...other} = this.props;
        return (
            <CustomNumber {...other} onClick={onClick}>
                {prefix}
                {
                    currency
                    ? utils.currencyFormatter.format(value)
                    : percent
                            ? utils.percentFormatter.format(value)
                            : utils.numberFormatter.format(value)
                }
                {percent ? '%' : postfix}
            </CustomNumber>
        );
    }
}

export default Number;
