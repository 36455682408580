import _ from 'lodash';

const defaultValue = {active: false};
const versionInProfile = _.get(window, 'appConfig.featureSwitch.versionInProfile', defaultValue);
const extendedHttpHeaders = _.get(window, 'appConfig.featureSwitch.extendedHttpHeaders', defaultValue);

export default {
    versionInProfile,
    extendedHttpHeaders
};
