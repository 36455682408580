import React, {Component} from 'react';
import Flex from 'components/grid/Flex';
import {withTranslation} from 'react-i18next';
import DetailsHeader from 'scenes/dashboard/components/DetailsHeader';
import DefaultGraphedRow from 'scenes/dashboard/components/DefaultGraphedRow';
import Filter from 'scenes/dashboard/components/Filter';
import {connect} from 'react-redux';
import connector from './Dispo.connect';
import filterConnector from '../components/Filter.connect';

export class Dispo extends Component {

    componentDidMount() {
        this.props.getDispoLotSize();
    }

    render() {
        const {t: translate, dispoTab, filterState} = this.props;
        return (
            <Flex item container column padding={5} style={{height: '100%', width: '97%', marginLeft: '1%'}}>
                <Filter refresh={this.props.getDispoLotSize}/>
                <DetailsHeader translate={translate}/>
                {
                    filterState.sortiment === 'TLMS' && (
                        <>
                        <DefaultGraphedRow data={dispoTab?.find(tab => tab.dataRow === 1)} subTitle={'Z1'}
                                        title={translate('global.dispo-title')}/>
                        <DefaultGraphedRow data={dispoTab?.find(tab => tab.dataRow === 2)} subTitle={'Z2'}
                                        title={translate('global.dispo-title')}/>
                        <DefaultGraphedRow data={dispoTab?.find(tab => tab.dataRow === 3)} subTitle={'Z3'}
                                        title={translate('global.dispo-title')}/>
                        <DefaultGraphedRow data={dispoTab?.find(tab => tab.dataRow === 4)} subTitle={'Z6'}
                                        title={translate('global.dispo-title')}/>
                        <DefaultGraphedRow data={dispoTab?.find(tab => tab.dataRow === 5)} subTitle={'ZA'}
                                        title={translate('global.dispo-title')}/>
                        </>
                    )
                }
                {
                    filterState.sortiment === 'THBR' && (
                        <DefaultGraphedRow data={dispoTab?.find(tab => tab.dataRow === 6)} subTitle={'ZH'}
                                   title={translate('global.dispo-title')}/>
                    )
                }
            </Flex>
        );
    }
}

export default connect(filterConnector.mapStateToProps, filterConnector.mapDispatchToProps)(connect(connector.mapStateToProps, connector.mapDispatchToProps)(
    withTranslation()(
        Dispo
    )
));
