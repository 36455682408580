import * as actionTypes from './Dashboard.action.types';

export function getConfiguration() {
    return {
        type: actionTypes.GET_CONFIGURATION,
        payload: {
            client: 'api',
            request: {
                url: '/configuration',
                method: 'get',
            }
        }
    }
}

export function getOverview() {
    return (dispatch, getState) => {
        return dispatch({
            type: actionTypes.GET_OVERVIEW,
            payload: {
                client: 'api',
                request: {
                    url: `/tabs/details/${getState().dashboardReducer.filterState.date}`,
                    method: 'get'
                }
            }
        })
    }
}

export function getDetails() {
    return (dispatch, getState) => {
        return dispatch({
            type: actionTypes.GET_DETAILS,
            payload: {
                client: 'api',
                request: {
                    url: `/tabs/details/${getState().dashboardReducer.filterState.date}`,
                    method: 'get'
                }
            }
        })
    }
}

export function getPlanDeliveryTime() {
    return (dispatch, getState) => {
        return dispatch({
            type: actionTypes.GET_PLAN_DELIVERY_TIMES,
            payload: {
                client: 'api',
                request: {
                    url: `/tabs/plan-delivery-times/${getState().dashboardReducer.filterState.date}`,
                    method: 'get'
                }
            }
        })
    }
}

export function getDispoLotSize() {
    return (dispatch, getState) => {
        return dispatch({
            type: actionTypes.GET_DISPO,
            payload: {
                client: 'api',
                request: {
                    url: `/tabs/dispo-lot-sizes/${getState().dashboardReducer.filterState.date}`,
                    method: 'get'
                }
            }
        })
    }
}

export function getGraphs() {
    return (dispatch, getState) => {
        return dispatch({
            type: actionTypes.GET_GRAPHS,
            payload: {
                client: 'api',
                request: {
                    url: `/tabs/graphs/${getState().dashboardReducer.filterState.date}`,
                    method: 'get'
                }
            }
        })
    }
}

export function getProductList() {
    return (dispatch, getState) => {
        return dispatch({
            type: actionTypes.GET_PRODUCT_LIST,
            payload: {
                client: 'api',
                request: {
                    url: `/tabs/product-list/${getState().dashboardReducer.filterState.date}`,
                    method: 'get'
                }
            }
        })
    }
}

export function setDate(date) {
    return {
        type: actionTypes.SET_DATE,
        data: date
    }
}

export function setSortState(sortState) {
    return {
        type: actionTypes.SET_SORT_STATE,
        data: {sortState}
    }
}

export function setFilterState(filterState) {
    return {
        type: actionTypes.SET_FILTER_STATE,
        data: filterState
    }
}
