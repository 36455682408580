export const LOAD_ACCESS_TOKEN = '@main/LOAD_ACCESS_TOKEN';

export const LOGIN = '@main/LOGIN';
export const LOGIN_SUCCESS = '@main/LOGIN_SUCCESS';
export const LOGIN_FAIL = '@main/LOGIN_FAIL';

export const REQUEST_NEW_PASSWORD = '@main/REQUEST_NEW_PASSWORD';
export const REQUEST_NEW_PASSWORD_SUCCESS = '@main/REQUEST_NEW_PASSWORD_SUCCESS';
export const REQUEST_NEW_PASSWORD_FAIL = '@main/REQUEST_NEW_PASSWORD_FAIL';
export const SAVE_NEW_PASSWORD = '@main/SAVE_NEW_PASSWORD';
export const SAVE_NEW_PASSWORD_SUCCESS = '@main/SAVE_NEW_PASSWORD_SUCCESS';
export const SAVE_NEW_PASSWORD_FAIL = '@main/SAVE_NEW_PASSWORD_FAIL';

export const LOGOUT = '@main/LOGOUT';

export const FETCH_USER = '@main/FETCH_USER';
export const FETCH_USER_SUCCESS = '@main/FETCH_USER_SUCCESS';
export const FETCH_USER_FAIL = '@main/FETCH_USER_FAIL';

export const LOAD_SETUP = '@main/LOAD_SETUP';
export const LOAD_SETUP_DONE = '@main/LOAD_SETUP_DONE';

export const SESSION_TIMEOUT = '@main/SESSION_TIMEOUT';
export const SET_SIDEBAR_EXPANDED = '@main/SET_SIDEBAR_EXPANDED';
