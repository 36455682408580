import React from 'react';
import {TextField} from '@mui/material';
import {styled} from '@mui/material/styles';

export default styled((props) => {
    const {InputLabelProps, size, variant, onEnterKey, onKeyPress, ...other} = props;

    const handleKeyPress = (onEnterKey, onKeyPress) => event => {
        const isEnterPressed = (event.key && event.key === 'Enter') || (event.keyCode && event.keyCode === 13);
        const isShiftPressed = event.shiftKey;
        if (isEnterPressed && !isShiftPressed){
            onEnterKey && onEnterKey(event);
        } else {
            onKeyPress && onKeyPress(event);
        }
    };

    return <TextField {...other}
               onKeyPress={handleKeyPress(onEnterKey, onKeyPress)}
               variant={variant || "outlined"}
               size={'small'}
               InputLabelProps={{shrink: true, ...InputLabelProps}}
    />
})(({ theme }) => ({
    minWidth: 150,
    marginRight: 7,
    marginTop: 10
}));
