import React, {Component} from 'react';
import Flex from 'components/grid/Flex';
import {
    concrete,
    transparentBlack03,
    transparentBlack087,
    htmlWhite,
    persianGreen
} from 'components/colors/Colors';
import {ExitToApp as LogoutIcon} from '@mui/icons-material';
import {
    ClickAwayListener,
    Typography,
    Divider,
    Button
} from '@mui/material';
import {Trans, withTranslation} from "react-i18next";
import HmoAvatar from 'components/avatar/HmoAvatar'
import FeatureSwitch from 'utils/FeatureSwitch';
import LanguageSelector from 'components/language-selector/LanguageSelector';
import {connect} from 'react-redux';
import connector from './Profile.connect';
import {getName} from 'domain/User.model';
import {styled} from '@mui/material/styles';
const ProfileContainer = styled((props) => <Flex {...props} />)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    boxShadow: `0 0 5px 0 ${transparentBlack03}`,
    left: 50,
    bottom: 0,
    color: transparentBlack087,
    backgroundColor: htmlWhite,
    fontSize: 13,
    fontFamily: 'Arial',
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: 0.01071,
    zIndex: 10,
    width: 'auto',
    paddingTop: 5
}));
const LogoutContainer = styled((props) => <Flex {...props} />)(({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    alignItems: 'center',
    justifyContent: "flex-start",
    paddingTop: 5,
    paddingBottom: 5,
    paddingRight: 9,
    backgroundColor: htmlWhite,
    '&:hover': {
        backgroundColor: concrete,
        cursor: 'pointer'
    },
    whiteSpace: 'nowrap',
    paddingLeft: 9
}));
const LanguageContainer = styled((props) => <Flex {...props} />)(({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    alignItems: 'center',
    paddingTop: 5,
    paddingBottom: 5,
    paddingRight: 9,
    backgroundColor: htmlWhite,
    '&:hover': {
        backgroundColor: concrete,
        cursor: 'pointer'
    },
    whiteSpace: 'nowrap',
    paddingLeft: 9
}));
const LogoutText = styled((props) => <Typography {...props} />)(({ theme }) => ({
    textTransform: 'none',
    '&:hover': {
        cursor: 'pointer'
    },
}));

export class Profile extends Component {

    state = {showProfile: false};

    toggleProfile = () => {
        this.setState({showProfile: !this.state.showProfile})
    };

    hideProfile = () => {
        this.setState({showProfile: false})
    };

    render() {
        const {t: translate, user, logout, positionStyle, isSidebarExpanded} = this.props;
        const {showProfile} = this.state;
        return (
            <ClickAwayListener onClickAway={this.hideProfile}>
                <div>
                    <Flex item container alignItems={'center'}>
                        <HmoAvatar {...{user: user}} onClick={this.toggleProfile}/>
                        {
                            isSidebarExpanded &&
                            <Button
                                onClick={this.toggleProfile}
                                style={{
                                    fontSize: 14, fontWeight: 'bold',
                                    color: 'black',
                                    textTransform: 'none'
                                }}>
                                <Trans i18nKey={'global.account'}/>
                            </Button>
                        }
                    </Flex>
                    {
                        showProfile &&
                        <ProfileContainer style={positionStyle}>
                            <Flex item container
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                  direction={'column'}
                                  style={{padding: 12}}
                            >
                                <HmoAvatar {...{user: user}}/>
                                <span style={{
                                    display: 'inline',
                                    textTransform: 'capitalize',
                                    fontWeight: 'bold'
                                }}>{getName(user)}</span>
                                <span style={{
                                    display: 'inline',
                                    whiteSpace: 'nowrap'
                                }}>{user.email}</span>
                            </Flex>
                            {
                                FeatureSwitch.versionInProfile.active &&
                                <span style={{
                                    color: persianGreen,
                                    fontSize: 12,
                                    textAlign: 'center'
                                }}>v.{process.env.REACT_APP_VERSION}</span>
                            }
                            <Divider/>
                            <LanguageContainer>
                                <LanguageSelector/>
                            </LanguageContainer>
                            <Divider/>
                            <LogoutContainer onClick={logout}>
                                <LogoutIcon style={{
                                    width: 29,
                                    height: 18,
                                    margin: 4
                                }}/>
                                <LogoutText>
                                    {translate("login.logout")}
                                </LogoutText>
                            </LogoutContainer>
                        </ProfileContainer>

                    }
                </div>
            </ClickAwayListener>
        )
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(withTranslation()(Profile));
